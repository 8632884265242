import styled from '@emotion/styled'
import React from 'react'

import Dimmed from '@common/Dimmed'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'
import { colors } from '@styles/colors'
import Spacing from '@common/Spacing'

interface AlertProps {
  open?: boolean
  title?: React.ReactNode
  description?: React.ReactNode
  buttonLabel?: string
  onComplete: () => void
}

const Alert = ({
  open,
  title,
  description,
  buttonLabel,
  onComplete,
}: AlertProps) => {
  if (open === false) return null

  return (
    <Dimmed>
      <AlertContainer>
        <Flex direction="column">
          {title && (
            <Text typography="24_Sb" color="gray600" textAlign="center">
              {title}
            </Text>
          )}

          <Spacing size={20} />
          {description && (
            <Text
              typography="14_Rg"
              color="gray500"
              textAlign="center"
              margin="12px 0px 20px 0px"
            >
              {description}
            </Text>
          )}
        </Flex>

        <Flex gap={10}>
          {onComplete && (
            <Button onClick={onComplete} size="large">
              <Text typography="14_Md" color="white">
                {buttonLabel}
              </Text>
            </Button>
          )}
        </Flex>
      </AlertContainer>
    </Dimmed>
  )
}

const AlertContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
  width: 400px;
  padding: 56px 40px;
  box-sizing: border-box;
`

export default Alert
