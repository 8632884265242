import { InputHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Flex from '@common/Flex'
import Text from '@common/Text'
import defaultProfile from '@assets/defaultProfile.svg'

interface MyProfileProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  size?: number
  description?: string
  mode?: 'default' | 'upload'
  profileImage?: string
}

const MyProfile = ({
  label,
  size,
  description,
  mode = 'default',
  onChange,
  profileImage,
}: MyProfileProps) => {
  return (
    <Flex justify="space-between">
      <Flex direction="column">
        {label && (
          <Text color="gray500" typography="14_Md">
            {label}
          </Text>
        )}
        <Text color="gray400" typography="12_Rg" css={subTextStyle}>
          {description}
        </Text>
      </Flex>
      <ProfileContainer>
        <img
          src={profileImage || defaultProfile}
          alt="사장님 프로필"
          width={size}
          height={size}
        />

        {mode === 'upload' && (
          <input type="file" accept="image/*" onChange={onChange} />
        )}
      </ProfileContainer>
    </Flex>
  )
}

const subTextStyle = css`
  margin-top: 8px;
`

export default MyProfile

const ProfileContainer = styled.div`
  position: relative;

  & > img {
    border-radius: 20px;
    object-fit: cover;
  }

  & > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`
