import { Outlet, NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import { useProfile } from '@hooks/useProfile'
import Flex from '@common/Flex'
import SideBarGuest from '@components/icon/SideBarGuest'
import SideBarSchedule from '@components/icon/SideBarSchedule'
import SideBarTicket from '@components/icon/SideBarTicket'
import Text from '@common/Text'
import MyProfile from '@components/profile/MyProfile'

const Layout = () => {
  const { profile } = useProfile()

  return (
    <Flex>
      <Sidebar>
        <Header direction="column" justify="center" align="center">
          <MyProfile
            profileImage={profile?.petKindergarden?.profileThumbnailUrl}
            size={120}
          />
          <Flex direction="column" gap={2}>
            <Text typography="18_Sb" color="gray600">
              {profile?.petKindergarden?.name}
            </Text>
            <Text typography="14_Rg" color="gray500">
              {profile?.user?.name} 사장님
            </Text>
          </Flex>
        </Header>

        <Content>
          <Menu>
            <Item to="/main/schedule">
              {({ isActive }) => (
                <>
                  <SideBarSchedule isOn={isActive} />
                  예약 일정
                </>
              )}
            </Item>
            <Item to="/main/customers">
              {({ isActive }) => (
                <>
                  <SideBarGuest isOn={isActive} />
                  고객 관리
                </>
              )}
            </Item>
            <Item to="/main/ticket">
              {({ isActive }) => (
                <>
                  <SideBarTicket isOn={isActive} />
                  이용권
                </>
              )}
            </Item>
          </Menu>

          <Footer>
            <NavLink to="/">사장님 가이드</NavLink>
            <Link
              href="https://slashpage.com/mungmanager/93nzyxmd4dkqzmwk6r45"
              target="_blank"
              rel="noopener noreferrer"
            >
              문의
            </Link>
            <NavLink to="/main/my">유치원 정보</NavLink>
            <NavLink to="/">로그아웃</NavLink>
          </Footer>
        </Content>
      </Sidebar>

      <Main>
        <Outlet />
      </Main>
    </Flex>
  )
}

export default Layout

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  min-width: 240px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 64px 12px 40px 12px;
  box-sizing: border-box;
  height: 100vh;
  position: fixed;
  background-color: ${colors.white};
  z-index: 2;
`

const Header = styled(Flex)`
  text-align: center;

  img {
    margin-bottom: 16px;
    border-radius: 20px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-top: 39px;
`

const Item = styled(NavLink)`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px 16px;
  color: ${colors.gray400};
  text-decoration: none;

  &.active {
    color: ${colors.black};
    background-color: ${colors.yellow50};
    border-radius: 8px;
  }

  &:hover {
    background-color: ${colors.gray50};
    border-radius: 8px;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  gap: 12px;
  font-size: 12px;
  color: ${colors.gray400};
  line-height: 15.6px;
`

const Link = styled.a`
  text-decoration: none;
  color: ${colors.gray400};
`

const Main = styled.div`
  margin-left: 240px;
  width: 1680px;
  white-space: nowrap;
`
