const HotelIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0831 24.4161C33.2943 25.6859 31.1259 26.4294 28.7888 26.4294C22.6577 26.4294 17.6875 21.3127 17.6875 15.0008C17.6875 10.7998 19.8893 7.12821 23.1697 5.14232C22.5109 5.04849 21.8382 5 21.1544 5C13.1074 5 6.58398 11.7157 6.58398 20C6.58398 28.2843 13.1074 35 21.1544 35C27.7084 35 33.2517 30.5451 35.0831 24.4161Z"
          fill="#8BAAF8"
        />
      </svg>
    </>
  )
}

export default HotelIcon
