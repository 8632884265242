import { CSSProperties } from 'react'
import styled from '@emotion/styled'

interface FlexProps {
  align?: CSSProperties['alignItems']
  justify?: CSSProperties['justifyContent']
  direction?: CSSProperties['flexDirection']
  gap?: CSSProperties['gap']
  flex?: CSSProperties['flex']
  width?: CSSProperties['width']
}

const Flex = styled.div<FlexProps>(
  ({ align, justify, direction, gap, flex, width }) => ({
    display: 'flex',
    alignItems: align,
    justifyContent: justify,
    flexDirection: direction,
    flex,
    width,
    gap,
  }),
)

export default Flex
