import styled from '@emotion/styled'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useRecoilValue } from 'recoil'

import { postReservation } from '@apis/calendar/calendar'
import { ReservationRequest } from '@/types/calendar'
import { colors } from '@styles/colors'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'
import Modal from '@common/Modal'
import DirectlyRegister from '@components/schedule/DirectlyRegister'
import Toast from '@common/Toast'
import { selectedDateState } from '@atoms/selectedDate'
import DayOff from '@components/schedule/ListByDate/DayOff'
import NoReservation from '@components/schedule/ListByDate/NoReservation'
import ScheduleList from '@components/schedule/ListByDate/ScheduleList'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

interface ListByDateProps {
  dailyReservations: {
    time: any[]
    allDay: any[]
    hotel: any[]
  }
  dayOffs: { id: number; dayOffAt: string }[]

  isPastDate: boolean
}

const ListByDate = ({
  dailyReservations,
  dayOffs,
  isPastDate,
}: ListByDateProps) => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)
  const queryClient = useQueryClient()
  const [activeModal, setActiveModal] = useState(false)
  const [finalData, setFinalData] = useState<ReservationRequest | null>(null)
  const [canRegist, setCanRegist] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const selectedDate = useRecoilValue(selectedDateState)
  const [toastMessage, setToastMessage] = useState('')

  const showModal = () => {
    setActiveModal(true)
  }

  const { mutate: postReservationMutation } = useMutation({
    mutationFn: (reservation: ReservationRequest) => {
      return postReservation(petKindergardenId as number, reservation)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendar'],
      })
      handleShowToast('예약이 등록되었습니다')
    },
    onError: (e) => {
      if (axios.isAxiosError(e)) {
        if (
          e?.response?.data?.code === 'already_exists_reservation_customer_pet'
        ) {
          handleShowToast('이미 등원이 예정되어 있습니다')
        } else {
          handleShowToast('등록 실패했습니다')
        }
      } else {
        handleShowToast('등록 실패했습니다')
      }
    },
  })

  const noReservations =
    !dailyReservations?.time?.length &&
    !dailyReservations?.allDay?.length &&
    !dailyReservations?.hotel?.length

  const isDayOff = dayOffs?.some((dayOff) => {
    return (
      dayOff.dayOffAt ===
      `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`
    )
  })

  const handleRegist = () => {
    if (finalData) {
      postReservationMutation(finalData)
      setActiveModal(false)
    }
  }

  const handleShowToast = (message: string) => {
    setToastMessage(message)
    setShowToast(true)
  }

  return (
    <Container direction="column">
      <Header align="center" justify="space-between">
        {selectedDate && (
          <Text typography="24_Sb">
            {`${selectedDate.month}월 ${selectedDate.day}일(${selectedDate.dayOfWeek})`}
          </Text>
        )}

        {!isDayOff && !isPastDate && (
          <Button color="line" onClick={showModal}>
            예약 직접 등록
          </Button>
        )}
      </Header>

      {isDayOff ? (
        <DayOff dayOffs={dayOffs} />
      ) : noReservations ? (
        <NoReservation />
      ) : (
        <ScheduleList
          dailyReservations={dailyReservations}
          handleShowToast={handleShowToast}
        />
      )}

      <Modal
        title="예약 직접 등록"
        leftButtonLabel="취소"
        rightButtonLabel="등록하기"
        showModal={activeModal}
        disabled={!canRegist}
        setShowModal={setActiveModal}
        rightButtonOnClick={handleRegist}
      >
        <DirectlyRegister
          setFinalData={setFinalData}
          setCanRegist={setCanRegist}
        />
      </Modal>

      <Toast
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
    </Container>
  )
}

export default ListByDate

const Container = styled(Flex)`
  min-width: 426px;
  padding: 0 32px 0 32px;
  border-left: 1px solid ${colors.gray200};
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
`

const Header = styled(Flex)`
  padding: 31px 0 11px 0;
  min-height: 107px;
  box-sizing: border-box;
`
