import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import Flex from '@common/Flex'
import { colors } from '@styles/colors'
import Text from '@common/Text'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Flex gap={20} css={{ flex: 1 }}>
          <Flex direction="column" gap={8}>
            <Text color="gray500" typography="14_Rg">
              상호명
            </Text>
            <Text color="gray500" typography="14_Rg">
              대표자
            </Text>
            <Text color="gray500" typography="14_Rg">
              사업자등록번호
            </Text>
            <Text color="gray500" typography="14_Rg">
              문의
            </Text>
          </Flex>

          <Flex direction="column" gap={8}>
            <Text color="gray600" typography="14_Rg">
              프로젝트사이드
            </Text>
            <Text color="gray600" typography="14_Rg">
              채정훈
            </Text>
            <Text color="gray600" typography="14_Rg">
              760-57-00654
            </Text>
            <Text color="gray600" typography="14_Rg">
              mung.manager@gmail.com
            </Text>
          </Flex>
        </Flex>
        <Flex direction="column" align="flex-end" gap={8}>
          <Text color="gray500" typography="14_Rg">
            <Link to="https://slashpage.com/mungmanager/terms" target="_blank">
              이용약관
            </Link>
          </Text>
          <Text color="gray500" typography="14_Rg">
            <Link
              to="https://slashpage.com/mungmanager/policy-partner"
              target="_blank"
              css={{ textDecoration: 'underline' }}
            >
              개인정보처리방침
            </Link>
          </Text>
          <br />
          <Text color="gray500" typography="14_Rg">
            Ⓒ 프로젝트사이드. All rights reserved.
          </Text>
        </Flex>
      </FooterWrapper>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  z-index: 1;
  border-top: 1px solid ${colors.gray100};
  margin-top: 120px;
`

const FooterWrapper = styled(Flex)`
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 0px;
`

export default Footer
