export const header = [
  {
    v: '멍매니저 고객 일괄 등록 템플릿',
    t: 's',
    s: { font: { bold: true, sz: '16' } },
  },
]

export const row = [
  {
    v: '휴대폰 번호는 -를 표기하여 입력해주세요 (예 : 010-1234-5678)\n강아지가 여러마리일 경우 , 로 구분하여 써주세요 (예 : 땅콩이, 초롱이, 쿠키)\n모든 값은 필수로 입력해야 합니다. 빈칸없이 작성해주세요.',
    t: 's',
  },
]

export const row2 = [
  {
    v: '보호자 이름',
    t: 's',
    s: { font: { bold: true } },
  },
  {
    v: '휴대폰 번호(-입력하여 표기)',
    t: 's',
    s: { font: { bold: true } },
  },
  {
    v: '강아지이름',
    t: 's',
    s: { font: { bold: true } },
  },
]
