import { KeyboardEventHandler, useState } from 'react'
import styled from '@emotion/styled'

import { CustomerSearchFormValues } from '@/types/form'
import Button from '@common/Button'
import Flex from '@common/Flex'
import TextField from '@common/TextField'
import ResetIcon from '@icon/ResetIcon'

interface SearchProps {
  onSearch: (search: CustomerSearchFormValues) => void
  active?: number
  onReset?: () => void
}

const CustomerSearch = ({ onSearch, onReset }: SearchProps) => {
  const [search, setSearch] = useState({
    name: '',
    phoneNumber: '',
    petName: '',
  })

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSearch({
      ...search,
      [name]: value,
    })
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      onSearch(search)
    }
  }

  const handleReset = () => {
    setSearch({
      name: '',
      phoneNumber: '',
      petName: '',
    })
    onReset && onReset()
  }

  return (
    <>
      <CustomerInputContainer direction="column">
        <InputContainer>
          <TextField
            placeholder="보호자 이름 검색"
            value={search.name}
            name="name"
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            width={'237'}
          />
          <TextField
            placeholder="휴대폰번호 검색"
            value={search.phoneNumber}
            name="phoneNumber"
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            width={'237'}
          />
          <TextField
            placeholder="강아지 이름 검색"
            value={search.petName}
            name="petName"
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            width={'237'}
          />
          <Button size="tiny" onClick={() => onSearch(search)}>
            검색
          </Button>
          <Flex align="center">
            <Button size="none" color="none" onClick={handleReset}>
              검색 초기화
            </Button>
            <ResetIcon />
          </Flex>
        </InputContainer>
      </CustomerInputContainer>
    </>
  )
}

const CustomerInputContainer = styled(Flex)``

const InputContainer = styled(Flex)`
  gap: 20px;
`

export default CustomerSearch
