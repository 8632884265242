import { useNavigate } from 'react-router-dom'

export const useInternalRouter = () => {
  const navigate = useNavigate()

  return {
    goBack: () => {
      navigate(-1)
    },
    push: (path: string) => {
      navigate(path)
    },
    replace: (path: string) => {
      navigate(path, { replace: true })
    },
  }
}
