import { forwardRef, InputHTMLAttributes } from 'react'
import { css } from '@emotion/react'

import Input from '@common/Input'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Spacing from '@common/Spacing'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hasError?: boolean
  errorMessage?: string | boolean
  required?: boolean
  textDescription?: string
  unit?: string
  width?: string
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    label,
    hasError,
    errorMessage,
    textDescription,
    required,
    unit,
    onFocus,
    onBlur,
    onMouseDown,
    width,
    ...rest
  } = props

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e)
  }

  return (
    <>
      {label && (
        <Flex align="center">
          <Text color="gray500" typography="14_Md" css={TextStyles}>
            {label}
          </Text>
          {required && <Text color="red600">*</Text>}
        </Flex>
      )}

      {textDescription && (
        <>
          <Text color="gray500" typography="13_Rg" css={TextStyles}>
            {textDescription}
          </Text>
          <Spacing size={8} />
        </>
      )}

      <Input
        {...ref}
        aria-invalid={hasError}
        onBlur={handleBlur}
        onMouseDown={onMouseDown}
        {...rest}
        width={width}
        unit={unit}
      />

      {errorMessage && (
        <Text typography="13_Rg" color="red600" css={ErrorTextStyles}>
          {errorMessage}
        </Text>
      )}
    </>
  )
})

const TextStyles = css`
  margin-bottom: 8px;
  margin-right: 2px;
`

const ErrorTextStyles = css`
  margin-top: 8px;
`

export default TextField
