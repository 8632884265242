import { useState } from 'react'

export const useHandleDirty = <T extends Record<string, any>>() => {
  const [dirty, setDirty] = useState<Partial<T>>({})

  const handleDirty = (name: keyof T) => {
    setDirty((prev) => ({
      ...prev,
      [name]: true,
    }))
  }

  return { handleDirty, dirty }
}
