import { css } from '@emotion/react'
import { useQuery } from '@tanstack/react-query'

import Button from '@common/Button'
import Flex from '@common/Flex'
import Text from '@common/Text'
import { colors } from '@styles/colors'
import { searchKindergarden } from '@apis/search/kindergarden'
import { KindergardenListResponse } from '@/types/kindergarden'

interface SearchDogNameProps {
  dogName: string
  setDogName: React.Dispatch<React.SetStateAction<string>>
  setShowDogName: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchDogName = ({
  dogName,
  setDogName,
  setShowDogName,
}: SearchDogNameProps) => {
  // 유치원 검색 useQuery
  const { data: kindergardenData } = useQuery<KindergardenListResponse>({
    queryKey: ['pet-kindergardens/search', dogName],
    queryFn: () => searchKindergarden(dogName),
    enabled: !!dogName,
  })

  // data를 클릭 했을 때 input값에 넣어주기
  const handleDogNameClick = (name: string) => {
    setDogName(name)
    setShowDogName(false)
  }

  // 검색해서 없는 유치원이면 새로 추가
  const addDogName = () => {
    setDogName(dogName)
    setShowDogName(false)
  }

  // keyword에 따라 리스트 검색
  const filteredData = kindergardenData?.results.filter((유치원) => {
    return 유치원.name.includes(dogName)
  })

  return (
    <Flex direction="column" css={SearchDogNameContainer}>
      <Flex direction="column" css={SearchDogNameList}>
        <ul>
          {filteredData &&
            filteredData.map((유치원, idx) => {
              const { name, address } = 유치원
              return (
                <li key={idx} onClick={() => handleDogNameClick(name)}>
                  <Text typography="14_Md" color="gray600">
                    {name}
                  </Text>
                  <Text typography="12_Rg" color="gray500">
                    {address}
                  </Text>
                </li>
              )
            })}
        </ul>

        <Flex css={ButtonBox}>
          <Button color="line" size="large" onClick={addDogName}>
            <Text typography="14_Md" color="gray400">
              + 새로 등록하기
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

const SearchDogNameContainer = css`
  background-color: white;
  box-sizing: border-box;
`

const SearchDogNameList = css`
  margin-top: 4px;
  border: 1px solid ${colors.gray200};
  border-radius: 8px;

  ul {
    width: 100%;
    height: 200px;
    overflow-y: auto;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    padding: 9px 16px;
    gap: 4px;
    border-bottom: 1px solid ${colors.gray200};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${colors.gray100};
      cursor: pointer;
    }
  }
`

const ButtonBox = css`
  border-top: 1px solid ${colors.gray200};
  padding: 16px;
`

export default SearchDogName
