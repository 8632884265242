import { useEffect, useRef, useState } from 'react'

export const useIntersection = <T extends HTMLElement>(src?: string) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const imgRef = useRef<T>(null)

  useEffect(() => {
    if (!imgRef.current) return

    getObserver().observe(imgRef.current)

    return () => {
      getObserver().disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  const getObserver = () => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersect = entries.some((entry) => entry.isIntersecting)

      if (isIntersect && src) {
        setImageSrc(src)
      }
    })

    return observer
  }

  return { imgRef, imageSrc }
}
