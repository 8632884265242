import { atom } from 'recoil'

import { SelectedDate } from '@/types/calendar'
import { DAYS_OF_WEEK } from '@constants/calendar'
import { padToTwoDigits } from '@utils/numberFormatting'

export const convertToSelectedDate = (date: Date): SelectedDate => ({
  day: padToTwoDigits(date.getDate()),
  month: padToTwoDigits(date.getMonth() + 1),
  year: date.getFullYear().toString(),
  dayOfWeek: DAYS_OF_WEEK[date.getDay()],
})

const today = new Date()

export const selectedDateState = atom<SelectedDate>({
  key: 'selectedDateState',
  default: convertToSelectedDate(today),
})
