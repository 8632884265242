import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Icon_Arrow from '@assets/Icon_Arrow.svg'
import Text from '@common/Text'

interface OptionType {
  label: string
  value: string
  disabled?: boolean
}

interface CustomSelectProps {
  options?: OptionType[]
  value: string
  onClick: (value: string) => void
  width?: string
  placeHolder?: string
  disabled?: boolean
}

const CustomSelect = ({
  options = [],
  value,
  onClick,
  width = '100%',
  placeHolder,
  disabled = false,
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectBoxRef = useRef<HTMLDivElement | null>(null)

  // 셀렉트 박스 외부 클릭 감지 및 닫기
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectBoxRef.current &&
        !selectBoxRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <SelectBoxContainer ref={selectBoxRef} width={width} disabled={disabled}>
      <SelectBoxHeader
        onClick={() => !disabled && setIsOpen(!isOpen)}
        disabled={disabled}
      >
        <Text typography="14_Rg">
          {value
            ? options.find((option) => option.value === value)?.label
            : placeHolder}
        </Text>

        <Arrow src={Icon_Arrow} alt="Arrow" isOpen={isOpen} />
      </SelectBoxHeader>

      {isOpen && (
        <>
          <SelectBoxList>
            {options.map((option) => (
              <SelectBoxItem
                key={option.value}
                disabled={option.disabled}
                onClick={() => {
                  if (!option.disabled) {
                    onClick(option.value)
                    setIsOpen(false)
                  }
                }}
              >
                <Text
                  typography="14_Rg"
                  color={option.disabled ? 'gray300' : undefined}
                >
                  {option.label}
                </Text>
              </SelectBoxItem>
            ))}
          </SelectBoxList>
        </>
      )}
    </SelectBoxContainer>
  )
}

export default CustomSelect

const SelectBoxContainer = styled.div<{ width: string; disabled: boolean }>`
  position: relative;
  width: ${({ width }) => width};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const SelectBoxHeader = styled.div<{ disabled: boolean }>`
  padding: 0 16px;
  background-color: ${({ disabled }) => (disabled ? colors.gray100 : '#fff')};
  border: 1px solid ${colors.gray200};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
`

const Arrow = styled.img<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`

const SelectBoxList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid ${colors.gray200};
  border-radius: 8px;
  z-index: 10;
  overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
  top: 52px;
  max-height: 320px;
  min-height: 56px;
`

const SelectBoxItem = styled.div<{ disabled?: boolean }>`
  padding: 0 16px;
  display: flex;
  align-items: center;
  min-height: 56px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'inherit' : colors.gray100};
  }
`
