import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { Profile } from '@/types/userInfo'

export interface UserState {
  name: string
  email: string
  phoneNumber: string
}

const { persistAtom: persistUserState } = recoilPersist({
  key: 'userInfo',
  storage: localStorage,
})

const { persistAtom: persistUserCheckState } = recoilPersist({
  key: 'userCheck',
  storage: localStorage,
})

export const userState = atom<UserState>({
  key: 'userState',
  default: {
    name: '',
    email: '',
    phoneNumber: '',
  },
  effects_UNSTABLE: [persistUserState],
})

export const userCheckState = atom<boolean>({
  key: 'userCheckState',
  default: false,
  effects_UNSTABLE: [persistUserCheckState],
})

export const profileState = atom<Profile>({
  key: 'profileState',
  default: {
    user: {
      name: '',
    },
    petKindergarden: {
      id: null,
      name: '',
      profileThumbnailUrl: '',
    },
  },
})
