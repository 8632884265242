import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors } from '@styles/colors'
import Flex from '@common/Flex'
import Text from '@common/Text'

interface RadioOptionType {
  label: string
  value: string
}

interface RadioProps {
  options?: RadioOptionType[]
  name: string
  label?: string
  required?: boolean
  onChange?: (value: string) => void
  selectedOption?: string
}

const Radio = ({
  options = [],
  name,
  label,
  required,
  onChange,
  selectedOption,
}: RadioProps) => {
  return (
    <>
      {label && (
        <Flex align="center">
          <Text color="gray500" typography="14_Md" css={textStyles}>
            {label}
          </Text>
          {required && <Text color="red600">*</Text>}
        </Flex>
      )}

      <Flex gap="8px">
        {options.map((option, idx) => (
          <RadioLabel key={idx} isChecked={option.value === selectedOption}>
            <RadioInput
              type="radio"
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              onChange={() => onChange && onChange(option.value)}
              checked={option.value === selectedOption}
            />
            {option.label}
          </RadioLabel>
        ))}
      </Flex>
    </>
  )
}

export default Radio

const textStyles = css`
  margin-bottom: 8px;
  margin-right: 2px;
`

const RadioInput = styled.input`
  display: none;
`

const RadioLabel = styled.label<{ isChecked: boolean }>`
  padding: 15.5px 0;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isChecked ? colors.yellow400 : colors.gray100};
  width: 100%;
  text-align: center;
  color: ${(props) => (props.isChecked ? colors.gray600 : colors.gray400)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`
