import { format } from 'date-fns'

export const generateDate = (date: string, type?: boolean) => {
  if (!date) return '-'

  const typeFormat = type ? 'yyyy년 MM월 dd일' : 'yyyy. MM. dd'

  const formattedDate = format(new Date(date), typeFormat)
  return formattedDate
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString)

  const year = date.getFullYear().toString().slice(2)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  const dayOfWeekNames = ['일', '월', '화', '수', '목', '금', '토']
  const dayOfWeek = dayOfWeekNames[date.getDay()]

  return `${year}.${month}.${day} (${dayOfWeek})`
}
