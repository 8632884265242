import { css } from '@emotion/react'

export const typographyMap = {
  '28_Sb': css`
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
  `,
  '24_Sb': css`
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
  `,
  '18_Sb': css`
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
  `,
  '18_Md': css`
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
  `,
  '16_Sb': css`
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
  `,
  '16_Md': css`
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
  `,
  '14_Sb': css`
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
  `,
  '14_Md': css`
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
  `,
  '14_Rg': css`
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
  `,
  '13_Md': css`
    font-size: 13px;
    font-weight: 500;
    line-height: 16.9px;
  `,
  '13_Rg': css`
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
  `,
  '12_Md': css`
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
  `,
  '12_Rg': css`
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
  `,
  '10_Md': css`
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
  `,
  '10_Rg': css`
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
  `,
}

export type Typography = keyof typeof typographyMap
