import { css } from '@emotion/react'

export const colorPalette = css`
  :root {
    --yellow600: #ffa31e;
    --yellow400: #ffce56;
    --yellow300: #ffe3a3;
    --yellow200: #ffebbc;
    --yellow50: #fff6e0;
    --red600: #fe5255;
    --red400: #f49396;
    --red300: #f8c9ca;
    --red200: #fce4e5;
    --red50: #fef6f6;
    --blue600: #3d71f9;
    --blue400: #8baaf8;
    --blue300: #c5d5fc;
    --blue200: #e2eafd;
    --blue50: #f5f8fe;
    --gray600: #272d3a;
    --gray500: #6e7a93;
    --gray400: #a0a8bd;
    --gray300: #c8ceda;
    --gray200: #e3e6ed;
    --gray100: #f3f4f9;
    --gray50: #f7f7fb;
    --white: #ffffff;
    --black: #000000;
  }
`

export const colors = {
  // 노랑
  yellow600: 'var(--yellow600)',
  yellow400: 'var(--yellow400)',
  yellow300: 'var(--yellow300)',
  yellow200: 'var(--yellow200)',
  yellow50: 'var(--yellow50)',

  // 빨강
  red600: 'var(--red600)',
  red400: 'var(--red400)',
  red300: 'var(--red300)',
  red200: 'var(--red200)',
  red50: 'var(--red50)',

  // 파랑
  blue600: 'var(--blue600)',
  blue400: 'var(--blue400)',
  blue300: 'var(--blue300)',
  blue200: 'var(--blue200)',
  blue50: 'var(--blue50)',

  // 회색
  gray600: 'var(--gray600)',
  gray500: 'var(--gray500)',
  gray400: 'var(--gray400)',
  gray300: 'var(--gray300)',
  gray200: 'var(--gray200)',
  gray100: 'var(--gray100)',
  gray50: 'var(--gray50)',

  // 기타
  white: 'var(--white)',
  black: 'var(--black)',
}

export type Colors = keyof typeof colors
