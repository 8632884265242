import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Flex from '@common/Flex'
import Text from '@common/Text'
import Button from '@common/Button'
import SignupCompleteLogo from '@icon/SIgnupCompleteLogo'
import { colors } from '@styles/colors'

interface CompleteListProps {
  // title, description 등 담당
  contents?: React.ReactNode
  // button 담당
  buttonContents?: React.ReactNode
  // 왼쪽 요소 담당
  left?: React.ReactNode
  // 오른쪽 요소 담당
  right?: React.ReactNode
  isIcon?: boolean
  isButton?: boolean
}

const CompleteList = ({
  contents,
  left,
  right,
  isIcon,
  buttonContents,
  isButton,
}: CompleteListProps) => {
  return (
    <Container isButton={isButton}>
      <Flex as="li">
        <Flex>{left}</Flex>
        <Flex css={ContentsContainer}>{contents}</Flex>
        <Flex>{right}</Flex>
        {isIcon && <SignupCompleteLogo />}
      </Flex>
      <Flex>{buttonContents}</Flex>
    </Container>
  )
}

// CompleteList의 Contents 담당
const Contents = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  return (
    <Flex direction="column">
      <Text typography="14_Sb" color="gray600">
        {title}
      </Text>
      <Text typography="12_Rg" color="gray500" css={ContentsDescription}>
        {description}
      </Text>
    </Flex>
  )
}

CompleteList.Contents = Contents

// CompleteList의 Button 담당
const ButtonContents = ({
  label,
  onClick,
}: {
  label: string
  onClick: () => void
}) => {
  return (
    <Button size="large" onClick={onClick} css={ButtonStyle}>
      <Text typography="14_Md" color="white">
        {label}
      </Text>
    </Button>
  )
}

CompleteList.ButtonContents = ButtonContents

const Container = styled.div<CompleteListProps>(({ isButton }) => ({
  backgroundColor: isButton ? colors.yellow50 : colors.gray50,
  padding: '19px 20px',
  borderRadius: '10px',
  marginBottom: '12px',
}))

const ContentsContainer = css`
  flex: 1;
`

const ContentsDescription = css`
  margin-top: 8px;
`

const ButtonStyle = css`
  margin-top: 16px;
`

export default CompleteList
