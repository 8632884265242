import { css } from '@emotion/react'

import { colors } from '@styles/colors'

export const buttonSize = {
  none: css`
    padding: 0px;
    width: 91px;
  `,
  tiny: css`
    padding: 15px 0px;
    width: 108px;
  `,
  small: css`
    padding: 15px 0px;
    width: 143px;
  `,
  mediumSmall: css`
    padding: 15px 0px;
    width: 155px;
  `,
  mediumMiddle: css`
    padding: 15px 0px;
    width: 172.5px;
  `,
  medium: css`
    padding: 11px 16px;
    width: 197px;
  `,
  mediumLarge: css`
    padding: 17.5px 24px;
  `,
  large: css`
    padding: 15px 0px;
    width: 306px;
  `,
}

export const buttonColor = {
  primary: css`
    color: ${colors.white};
    background-color: ${colors.yellow600};
  `,
  normal: css`
    color: ${colors.gray400};
    background-color: ${colors.gray100};
  `,
  line: css`
    color: ${colors.gray400};
    background-color: ${colors.white};
    border: 1px solid ${colors.gray200};
  `,
  alert: css`
    color: ${colors.red600};
    background-color: ${colors.red200};
  `,
  none: css`
    border: none;
    color: ${colors.gray400};
  `,
}

export type ButtonSize = keyof typeof buttonSize
export type ButtonColor = keyof typeof buttonColor
