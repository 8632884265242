const SideBarTicket = ({ isOn = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9231 7H6.07692C5.48215 7 5 7.49747 5 8.11111V15.8889C5 16.5026 5.48215 17 6.07692 17H17.9231C18.5179 17 19 16.5026 19 15.8889V8.11111C19 7.49747 18.5179 7 17.9231 7Z"
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6364H16"
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.3636H13"
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SideBarTicket
