import { useQuery } from '@tanstack/react-query'
import { useRecoilValue } from 'recoil'

import { getMonthlyReservations } from '@apis/calendar/calendar'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

export const useMonthlyReservations = (currentMonth: Date) => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)

  return useQuery({
    queryKey: [
      'calendar',
      currentMonth.getMonth() + 1,
      currentMonth.getFullYear(),
    ],
    queryFn: () =>
      getMonthlyReservations(
        currentMonth.getMonth() + 1,
        currentMonth.getFullYear(),
        petKindergardenId as number,
      ),
    enabled: !!petKindergardenId,
  })
}
