import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { ErrorBoundary } from 'react-error-boundary'
import styled from '@emotion/styled'

import Button from '@common/Button'
import Flex from '@common/Flex'
import Text from '@common/Text'
import { useInternalRouter } from '@hooks/useInternalRouter'
import notImage from '@assets/images/not_list.png'

const QueryErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const router = useInternalRouter()

  const { reset } = useQueryErrorResetBoundary()

  const gotoHome = () => {
    router.replace('/')
  }

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <ErrorContainer direction="column" align="center" gap={30}>
          <img src={notImage} alt="에러 이미지" width={130} height={130} />

          <Text typography="24_Sb" color="gray600">
            문제가 생겼다 멍...ㅠㅠ
          </Text>
          <Text typography="14_Rg" color="gray500" textAlign="center">
            잠시 후에 다시 시도해 주세요. <br />
            문제가 지속되면 고객센터로 문의해 주세요.
          </Text>
          <Flex gap={10}>
            <Button color="line" onClick={() => resetErrorBoundary()}>
              <Text typography="14_Md" color="gray400">
                다시 시도하기
              </Text>
            </Button>
            <Button color="primary" onClick={gotoHome}>
              <Text typography="14_Md" color="white">
                시작페이지로 이동
              </Text>
            </Button>
          </Flex>
        </ErrorContainer>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}

const ErrorContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default QueryErrorBoundary
