import styled from '@emotion/styled'

import { colors } from '@styles/colors'

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  border-radius: 20px;
`

export default Container
