import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import { CustomerTicket } from '@/types/customer'
import addDelimiter from '@utils/addDelimiter'
import Flex from '@common/Flex'
import Text from '@common/Text'
import CheckRadioIcon from '@icon/CheckRadioIcon'
import DayIcon from '@icon/DayIcon'
import HotelIcon from '@icon/HotelIcon'
import TimeIcon from '@icon/TimeIcon'

interface TicketListRowProps {
  left?: React.ReactNode
  isIcon?: boolean
  contents?: React.ReactNode
  selectedTicketId: number | null
  onSelect: (id: number) => void
  ticket: CustomerTicket
}

const TicketListRow = ({
  left,
  contents,
  selectedTicketId,
  onSelect,
  ticket,
}: TicketListRowProps) => {
  const { id } = ticket
  return (
    <TicketListContainer
      isSelected={selectedTicketId === id}
      onClick={() => onSelect(id)}
    >
      <Flex>{left}</Flex>
      <Flex align="center" flex={1} style={{ padding: '0 20px 0 24px' }}>
        <Flex flex={1}>{contents}</Flex>
        <Flex>
          <CheckRadioIcon isSelected={selectedTicketId === id} />
        </Flex>
      </Flex>
    </TicketListContainer>
  )
}

interface LeftProps {
  ticketType: string
}

const Left = ({ ticketType }: LeftProps) => {
  const Image = () => {
    if (ticketType === '시간') {
      return <TimeIcon />
    } else if (ticketType === '종일') {
      return <DayIcon />
    } else {
      return <HotelIcon />
    }
  }

  const colorStyle =
    ticketType === '시간'
      ? 'yellow600'
      : ticketType === '종일'
        ? 'red400'
        : 'blue400'

  return (
    <LeftContainer
      ticketType={ticketType}
      direction="column"
      justify="center"
      align="center"
      gap={9}
    >
      {Image()}
      <Text typography="12_Md" color={colorStyle}>
        {ticketType}
      </Text>
    </LeftContainer>
  )
}

TicketListRow.Left = Left

interface ContentsProps {
  ticket: CustomerTicket
}

const Contents = ({ ticket }: ContentsProps) => {
  const { price, ticketType, usageCount, usagePeriodInDaysCount, usageTime } =
    ticket

  const ticketTypeText = () => {
    let ticketText = ''

    if (ticketType === '시간') {
      ticketText = `${usageTime}시간 ・ ${usageCount}회`
    } else if (ticketType === '종일') {
      ticketText = `${usageCount}회`
    } else {
      ticketText = `${usageCount}박`
    }

    return ticketText
  }

  return (
    <Flex>
      <Flex direction="column">
        <Text typography="24_Sb" color="gray600">
          {ticketTypeText()}
        </Text>
        <Text typography="12_Rg" color="gray500">
          등록 후 {usagePeriodInDaysCount}일 내 사용
        </Text>

        <Text typography="12_Rg" color="gray500">
          {addDelimiter(price)}원
        </Text>
      </Flex>
    </Flex>
  )
}

TicketListRow.Contents = Contents

const TicketListContainer = styled(Flex)<{ isSelected: boolean }>`
  border-radius: 10px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? colors.gray600 : colors.gray200)};

  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }
`

const LeftContainer = styled(Flex)<{ ticketType: string }>`
  padding: 30px 28px;
  border-radius: 10px 0 0 10px;

  ${({ ticketType }) =>
    ticketType === '시간' &&
    `
    background-color: ${colors.yellow50};
    color: ${colors.yellow600};
  `}

  ${({ ticketType }) =>
    ticketType === '종일' &&
    `
    background-color: ${colors.red50};
    color: ${colors.red600};
  `}

  ${({ ticketType }) =>
    ticketType === '호텔' &&
    `
    background-color: ${colors.blue50};
    color: ${colors.blue600};
  `}
`

export default TicketListRow
