import * as XLSX from 'xlsx-js-style'

export const convertExcelToCSV = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    // 파일 읽기
    const reader = new FileReader()

    // 파일이 성공적으로 읽어들여졌을 때
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer)
      const workbook = XLSX.read(data, { type: 'array' })
      const csv = XLSX.utils.sheet_to_csv(
        workbook.Sheets[workbook.SheetNames[0]],
      )

      const csvBlob = new Blob([csv], { type: 'text/csv' })
      const csvFile = new File(
        [csvBlob],
        file.name.replace(/\.[^/.]+$/, '.csv'),
        { type: 'text/csv' },
      )

      resolve(csvFile)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}
