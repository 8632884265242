import styled from '@emotion/styled'
import { useRef, useState, MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { CustomerLists } from '@/types/customer'
import { generateDate } from '@utils/generateDate'
import { useProfile } from '@hooks/useProfile'
import { QUERY_KEY } from '@constants/queryKey'
import { updateCustomerActive } from '@apis/customer/customer'
import { useToast } from '@contexts/ToastContext'
import { colors } from '@styles/colors'
import { USER_INACTIVE_TABLE_HEAD, USER_TABLE_HEAD } from '@constants/userInfo'
import Flex from '@common/Flex'
import Table from '@common/Table'
import Text from '@common/Text'
import NotList from '@components/customer/NotList'

const yellow = require('@assets/images/yellow.png')
const red = require('@assets/images/red.png')
const blue = require('@assets/images/blue.png')

const CustomerListTable = ({
  currentTab,
  customerList,
}: {
  currentTab?: number
  customerList: CustomerLists
}) => {
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isDrag, setIsDrag] = useState(false)
  const dragRef = useRef({ x: 0, y: 0 })

  const { profile } = useProfile()

  const { mutate } = useMutation({
    mutationFn: (id: number) =>
      updateCustomerActive({
        pet_kindergarden_id: profile?.petKindergarden?.id,
        customer_id: id,
      }),

    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.CUSTOMER_LIST] })
      toast('활성 상태로 변경되었습니다.')
    },

    onError() {
      toast('활성 상태 변경에 실패했습니다.')
    },
  })

  const onActive = (id: number, event: any) => {
    event.stopPropagation()
    mutate(id)
  }

  const goToCustomerDetail = (id: number) => {
    if (isDrag) return
    navigate(`/main/customers/${id}`)
  }

  const handleDrag: MouseEventHandler<HTMLTableRowElement> = (e) => {
    const dx = e.clientX - dragRef.current.x
    const dy = e.clientY - dragRef.current.y

    if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
      setIsDrag(true)
    }
  }

  const handleMouseDown: MouseEventHandler<HTMLTableRowElement> = (e) => {
    dragRef.current = { x: e.clientX, y: e.clientY }
    setTimeout(() => setIsDrag(false), 0)
  }

  return (
    <>
      <CustomerListContainer>
        {currentTab === 0 ? (
          <Table head={USER_TABLE_HEAD}>
            {customerList?.results.length ? (
              <>
                {customerList.results.map((content) => {
                  return (
                    <tr
                      key={content.id}
                      onClick={() => goToCustomerDetail(content.id)}
                      onMouseMove={handleDrag}
                      onMouseDown={handleMouseDown}
                    >
                      <td style={{ width: '7%' }}>{content.name}</td>
                      <td style={{ width: '10%' }}>{content.phoneNumber}</td>
                      <td style={{ width: '20%' }}>
                        {content.customerPets.map((pet) => {
                          return (
                            <TablePetText key={pet.id} typography="12_Md">
                              {pet.name}
                            </TablePetText>
                          )
                        })}
                      </td>
                      <td style={{ width: '23%' }}>
                        {content?.customerTickets.slice(0, 3)?.map((ticket) => {
                          const { ticketType, usageTime } = ticket.ticket

                          const { usedCount, totalCount } = ticket

                          const ticketText = () => {
                            let text = ''

                            if (ticketType === '시간') {
                              text = `${totalCount - usedCount}/${totalCount}회(${usageTime}h)`
                            } else {
                              text = `${totalCount - usedCount}/${totalCount}회`
                            }

                            return text
                          }

                          const icon =
                            ticketType === '시간'
                              ? yellow
                              : ticketType === '종일'
                                ? red
                                : blue
                          return (
                            <CellFlex
                              style={{ display: 'inline-block' }}
                              ticketType={ticketType}
                              key={ticket.id}
                            >
                              <img src={icon} alt="아이콘" />
                              <TableTicketText
                                typography="12_Md"
                                ticketType={ticketType}
                              >
                                {ticketText()}
                              </TableTicketText>
                            </CellFlex>
                          )
                        })}
                      </td>
                      <td style={{ width: '20%' }}>{content.memo}</td>
                      <td style={{ width: '10%' }}>
                        {generateDate(content.recentReservedAt)}
                      </td>
                      <td style={{ width: '10%' }}>
                        {generateDate(content.createdAt)}
                      </td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <NotList
                title="추가된 고객이 없다멍..."
                description={
                  <>
                    우측 상단의 고객 등록으로 <br />
                    고객 정보를 등록하고 편리하게 관리해 보세요.
                  </>
                }
              />
            )}
          </Table>
        ) : (
          <Table head={USER_INACTIVE_TABLE_HEAD}>
            {customerList.results.map((content) => {
              return (
                <tr
                  key={content.id}
                  onClick={() => goToCustomerDetail(content.id)}
                >
                  <td>{content.name}</td>
                  <td>{content.phoneNumber}</td>
                  <td>
                    {content.customerPets.map((pet) => (
                      <TablePetText key={pet.id} typography="12_Md">
                        {pet.name}
                      </TablePetText>
                    ))}
                  </td>
                  <td>{content.memo}</td>
                  <td>{generateDate(content.recentReservedAt)}</td>
                  <td>{generateDate(content.createdAt)}</td>
                  <ActiveTd onClick={(e) => onActive(content.id, e)}>
                    활성 상태 변경
                  </ActiveTd>
                </tr>
              )
            })}
          </Table>
        )}
      </CustomerListContainer>
    </>
  )
}

const CustomerListContainer = styled.div`
  width: 100%;
`

const CellFlex = styled(Flex)<{ ticketType?: string }>`
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;

  ${({ ticketType }) =>
    ticketType === '시간' &&
    css`
      background-color: ${colors.yellow50};
      color: ${colors.yellow600};
    `}
  ${({ ticketType }) =>
    ticketType === '종일' &&
    css`
      background-color: ${colors.red50};
      color: ${colors.red600};
    `}
    ${({ ticketType }) =>
    ticketType === '호텔' &&
    css`
      background-color: ${colors.blue50};
      color: ${colors.blue600};
    `};

  img {
    vertical-align: middle;
  }
`

const TablePetText = styled(Text)`
  display: inline-block;
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 4px;
  color: ${colors.gray500};
  background-color: ${colors.gray100};
`

const TableTicketText = styled(Text)<{ ticketType?: string }>`
  margin-right: 4px;
  display: inline-block;
  ${({ ticketType }) =>
    ticketType === '시간' &&
    css`
      color: ${colors.yellow600};
    `}
  ${({ ticketType }) =>
    ticketType === '종일' &&
    css`
      color: ${colors.red600};
    `}
    ${({ ticketType }) =>
    ticketType === '호텔' &&
    css`
      color: ${colors.blue600};
    `};
`

const ActiveTd = styled.td`
  color: ${colors.gray400} !important;
  cursor: pointer;
  z-index: 100;
`

export default CustomerListTable
