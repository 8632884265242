import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import styled from '@emotion/styled'
import { useRecoilValue } from 'recoil'
import { ChangeEvent, useEffect, useState } from 'react'

import Flex from '@common/Flex'
import Spacing from '@common/Spacing'
import TextArea from '@common/Textarea'
import TextField from '@common/TextField'
import MyProfile from '@components/profile/MyProfile'
import Text from '@common/Text'
import SelectBox from '@common/SelectBox'
import { 예약변경여부, 예약여부 } from '@constants/signup'
import CheckboxField from '@common/CheckboxField'
import Button from '@common/Button'
import { useProfile } from '@hooks/useProfile'
import { QUERY_KEY } from '@constants/queryKey'
import { getKindergardenInfo, updateKindergardenInfo } from '@apis/user/user'
import { KindergardenInfo } from '@/types/kindergarden'
import DefaultImage from '@assets/images/default_profile.png'
import { fileUpload } from '@apis/upload/fileUpload'
import { validate } from '@utils/validation'
import { userState } from '@atoms/user'
import { useToast } from '@contexts/ToastContext'
import { Axios, AxiosError } from 'axios'
import { useAlertContext } from '@contexts/AlertContext'

const EditInfoPage = () => {
  const user = useRecoilValue(userState)
  const queryClient = useQueryClient()
  const [profileImage, setProfileImage] = useState<string>(DefaultImage)
  const [description, setDescription] = useState<string>('')
  const [kindergardenPhoneNumber, setKindergardenPhoneNumber] =
    useState<string>('')

  const [visiblePhoneNumber, setVisiblePhoneNumber] = useState({})

  // 개인 전화번호
  const [isIndividualPhone, setIsIndividualPhone] = useState<boolean>(false)

  // 유치원 전화번호
  const [isKindergardenPhone, setIsKindergardenPhone] = useState<boolean>(false)

  const [dailyPetLimit, setDailyPetLimit] = useState<number>(-1)
  const [permitPetCheck, setPermitPetCheck] = useState<boolean>(false)

  const { profile } = useProfile()

  const toast = useToast()
  const { open } = useAlertContext()

  const { data: kindergardenInfo } = useQuery<KindergardenInfo>({
    queryKey: [QUERY_KEY.KINDERGARDEN_INFO, profile?.petKindergarden?.id],
    queryFn: () => getKindergardenInfo(profile?.petKindergarden?.id),
    enabled: !!profile?.petKindergarden?.id,
  })

  const [selectValue, setSelectValue] = useState({
    businessStartHour: '',
    businessEndHour: '',
    reservationAvailabilityOption: '',
    reservationChangeOption: '',
  })

  const [imageValue, setImageValue] = useState({
    mainThumbnailUrl: '',
    profileThumbnailUrl: '',
  })

  useEffect(() => {
    if (kindergardenInfo) {
      setDescription(kindergardenInfo.guideMessage)
      setKindergardenPhoneNumber(kindergardenInfo.phoneNumber)
      setProfileImage(kindergardenInfo.profileThumbnailUrl)
      setSelectValue({
        businessStartHour: formatTime(kindergardenInfo.businessStartHour),
        businessEndHour: formatTime(kindergardenInfo.businessEndHour),
        reservationAvailabilityOption:
          kindergardenInfo.reservationAvailabilityOption,
        reservationChangeOption: kindergardenInfo.reservationChangeOption,
      })

      setDailyPetLimit(kindergardenInfo.dailyPetLimit)
      setIsIndividualPhone(
        Boolean(kindergardenInfo.visiblePhoneNumber.userPhoneNumber),
      )

      setIsKindergardenPhone(
        Boolean(kindergardenInfo.visiblePhoneNumber.petKindergardenPhoneNumber),
      )
      setVisiblePhoneNumber({
        ...visiblePhoneNumber,
        userPhoneNumber: kindergardenInfo.visiblePhoneNumber.userPhoneNumber,
        petKindergardenPhoneNumber:
          kindergardenInfo.visiblePhoneNumber.petKindergardenPhoneNumber,
      })
    }
  }, [kindergardenInfo])

  const { mutate } = useMutation({
    mutationFn: () => {
      const petKidergardenNumber = isKindergardenPhone
        ? kindergardenPhoneNumber
        : ''

      const userPhoneNumber = isIndividualPhone ? user.phoneNumber : ''

      const formValues = {
        guideMessage: description,
        phoneNumber: petKidergardenNumber,
        businessStartHour: selectValue.businessStartHour,
        businessEndHour: selectValue.businessEndHour,
        reservationAvailabilityOption:
          selectValue.reservationAvailabilityOption,
        reservationChangeOption: selectValue.reservationChangeOption,
        dailyPetLimit,
        mainThumbnailUrl: imageValue.mainThumbnailUrl,
        profileThumbnailUrl: imageValue.profileThumbnailUrl,
        visiblePhoneNumber: {
          ...visiblePhoneNumber,
          userPhoneNumber: userPhoneNumber,
          petKindergardenPhoneNumber: petKidergardenNumber,
        },
      }

      return updateKindergardenInfo(profile?.petKindergarden?.id, formValues)
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.KINDERGARDEN_INFO, profile?.petKindergarden?.id],
      })
      toast('유치원 정보가 수정되었습니다.')
    },

    onError: (error: AxiosError) => {
      handleError(error)
    },
  })

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      open({
        title: '유치원 정보 수정 실패',
        description: '필수 항목을 입력해주세요.',
        onComplete: () => {},
        buttonLabel: '확인',
      })
    } else if (error.response?.status === 500) {
      open({
        title: '유치원 정보 수정 실패',
        description: '서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
        onComplete: () => {},
        buttonLabel: '확인',
      })
    }
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      let imgURL = window.URL.createObjectURL(file)

      setProfileImage(imgURL)

      let formData = new FormData()
      formData.append('file', file)
      formData.append('resource_type', 'pet_kindergarden')

      const { fileUrl } = await fileUpload(formData)

      setImageValue({
        ...imageValue,
        mainThumbnailUrl: fileUrl,
        profileThumbnailUrl: fileUrl,
      })
    }
  }

  const handleChangeTextValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }

  const handleChangeNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (value === '') {
      setIsKindergardenPhone(false)
    }

    const hyphenNumber = validate.addPhoneHyphen(value)

    setKindergardenPhoneNumber(hyphenNumber)
  }

  const handleChangeSelectValue = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    setSelectValue({
      ...selectValue,
      [name]: value,
    })
  }

  const handleChangePetLimitValue = (e: ChangeEvent<HTMLInputElement>) => {
    setDailyPetLimit(Number(e.target.value))
  }

  const handlePermitPetCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target

    setPermitPetCheck(checked)

    const isCheck = checked ? -1 : kindergardenInfo?.dailyPetLimit || 0

    setDailyPetLimit(isCheck)
  }

  const handleKindergardenNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
    setVisiblePhoneNumber((prev) => ({
      ...prev,
      petKindergardenPhoneNumber: e.target.checked
        ? kindergardenPhoneNumber
        : '',
    }))
    setIsKindergardenPhone(e.target.checked)
  }

  const handleIndividualNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
    setVisiblePhoneNumber((prev) => ({
      ...prev,
      userPhoneNumber: e.target.checked ? user.phoneNumber : '',
    }))
    setIsIndividualPhone(e.target.checked)
  }

  const onSave = () => {
    mutate()
  }

  if (!kindergardenInfo) {
    return null
  }

  const formatTime = (time: string) => {
    const [hour, minute] = time.split(':')

    return `${hour}:${minute}`
  }

  const isDisabled = () => {
    const {
      businessStartHour,
      businessEndHour,
      reservationAvailabilityOption,
      reservationChangeOption,
    } = selectValue

    if (
      (businessEndHour &&
        businessStartHour &&
        reservationAvailabilityOption &&
        reservationChangeOption &&
        isIndividualPhone) ||
      isKindergardenPhone
    ) {
      return false
    }

    return true
  }

  return (
    <EditInfoContainer>
      <Flex direction="column">
        <MyProfile
          size={120}
          label="유치원 프로필 사진"
          mode="upload"
          description="5mb 이하"
          profileImage={profileImage}
          onChange={handleFileUpload}
        />

        <Spacing size={24} />

        <TextField
          label="강아지 유치원명"
          placeholder="유치원명 검색"
          required
          disabled
          value={kindergardenInfo.name}
        />

        <Spacing size={24} />

        <TextField
          label="주소"
          placeholder="주소 검색"
          required
          value={kindergardenInfo.roadAddress}
          readOnly
          disabled
        />

        <Spacing size={8} />

        <TextField
          placeholder="상세주소 입력"
          value={kindergardenInfo.detailAddress}
          disabled
        />

        <Spacing size={24} />

        <TextArea
          label="안내사항"
          placeholder="안내사항의 내용은 유치원 고객(보호자)에게 노출됩니다."
          value={description}
          onChange={handleChangeTextValue}
        />

        <Spacing size={24} />

        <TextField
          label="유치원 전화번호"
          placeholder="유치원 전화번호"
          value={kindergardenPhoneNumber}
          onChange={handleChangeNumberValue}
        />

        <Spacing size={24} />

        <Flex align="center">
          <Text
            color="gray500"
            typography="14_Md"
            style={{ margin: '0px 2px 8px 0px' }}
          >
            영업시간
          </Text>
          <Text color="red600">*</Text>
        </Flex>
        <Flex align="center" gap="8px">
          <SelectBox
            type="time"
            onChange={handleChangeSelectValue}
            value={selectValue.businessStartHour}
            name="businessStartHour"
          />
          ~
          <SelectBox
            type="time"
            onChange={handleChangeSelectValue}
            value={selectValue.businessEndHour}
            name="businessEndHour"
          />
        </Flex>

        <Spacing size={24} />
        <SelectBox
          label="예약 가능 여부"
          name="reservationAvailabilityOption"
          width="100%"
          options={예약여부}
          required
          value={selectValue.reservationAvailabilityOption}
          onChange={handleChangeSelectValue}
        />
        <Spacing size={24} />
        <SelectBox
          label="예약 변경 여부"
          name="reservationChangeOption"
          options={예약변경여부}
          width="100%"
          required
          value={selectValue.reservationChangeOption}
          onChange={handleChangeSelectValue}
        />

        <Spacing size={24} />

        <TextField
          label="하루 정원 설정"
          placeholder={(dailyPetLimit === -1 && '제한 없음') || ''}
          required
          value={dailyPetLimit === -1 ? '' : dailyPetLimit}
          onChange={handleChangePetLimitValue}
          disabled={dailyPetLimit === -1}
          unit="마리"
        />

        <Spacing size={3} />

        <CheckboxField
          label="제한 없음"
          isChecked={permitPetCheck}
          onChange={handlePermitPetCheck}
        />

        <Spacing size={24} />

        <Flex align="center">
          <Text
            color="gray500"
            typography="14_Md"
            style={{ margin: '0px 2px 8px 0px' }}
          >
            고객에게 노출할 연락처
          </Text>
          <Text color="red600">*</Text>
        </Flex>
        <Flex>
          <Text color="gray500" typography="12_Rg">
            1개 이상 선택
          </Text>
        </Flex>

        <Spacing size={8} />
        <CheckboxField
          label="휴대폰 번호"
          isChecked={isIndividualPhone}
          onChange={handleIndividualNumberValue}
        />
        <Spacing size={11} />
        <CheckboxField
          label="유치원 전화번호"
          isChecked={isKindergardenPhone}
          disabled={kindergardenPhoneNumber === ''}
          onChange={handleKindergardenNumberValue}
        />

        <Spacing size={64} />

        <Button full onClick={onSave} disabled={isDisabled()}>
          저장
        </Button>
      </Flex>
    </EditInfoContainer>
  )
}

export default EditInfoPage

const EditInfoContainer = styled.div`
  width: 100%;
  margin: 64px auto;
  max-width: 306px;
`
