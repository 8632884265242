import styled from '@emotion/styled'

import Flex from '@common/Flex'
import Text from '@common/Text'
import Logo from '@assets/mung-logo.svg'
import Button from '@common/Button'
import { useInternalRouter } from '@hooks/useInternalRouter'

const Navbar = () => {
  const router = useInternalRouter()
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_REST_API}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&response_type=code`

  const kakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL
    router.replace('/auth/kakao/callback')
  }

  return (
    <>
      <NavbarContainer>
        <NavbarWrapper direction="row" justify="space-between" align="center">
          <Flex>
            <img src={Logo} alt="멍매니저 로고" />
          </Flex>
          <Flex>
            <Button onClick={kakaoLogin}>
              <Text typography="14_Md" color="white">
                멍매니저 시작하기
              </Text>
            </Button>
          </Flex>
        </NavbarWrapper>
      </NavbarContainer>
    </>
  )
}

const NavbarContainer = styled.nav`
  width: 100%;
  background-color: #fff6e0;
`

const NavbarWrapper = styled(Flex)`
  width: 100%;
  max-width: 960px;
  height: 80px;
  margin: 0 auto;
`

export default Navbar
