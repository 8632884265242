export const validate = {
  isEmail: (email: string) => {
    const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
    return emailReg.test(email)
  },

  isName: (name: string) => {
    const nameReg = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]*$/
    return nameReg.test(name)
  },

  isPhone: (phone: string) => {
    const phoneReg = /^(010)-?\d{4}-?\d{4}$/
    return phoneReg.test(phone)
  },

  isNone: (value: string) => {
    const noneReg = /^\s*$/
    return noneReg.test(value)
  },

  addPhoneHyphen: (phone: string) => {
    const onlyNumber = phone.replace(/\D/g, '')

    const formattedPhoneNumber = onlyNumber.replace(
      /(\d{3})(\d{4})(\d{4})/,
      '$1-$2-$3',
    )

    return formattedPhoneNumber
  },
}
