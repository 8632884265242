import { ChangeEvent, useCallback, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import Flex from '@common/Flex'
import Button from '@common/Button'
import Spacing from '@common/Spacing'
import TextField from '@common/TextField'
import MyProfile from '@components/profile/MyProfile'
import TextArea from '@common/Textarea'
import Address from '@components/daum/Address'
import SearchDogNameModal from '@components/signup/SearchDogName'
import SelectBox from '@common/SelectBox'
import CheckboxField from '@common/CheckboxField'
import Text from '@common/Text'
import ProfileImage from '@assets/images/default_profile.png'
import { 예약여부, 예약변경여부 } from '@constants/signup'
import { addressState } from '@atoms/address'
import { fileUpload } from '@apis/upload/fileUpload'
import { userState } from '@atoms/user'
import { addressValues, numberFormValues, userFormValues } from '@/types/form'
import { validate } from '@utils/validation'

interface SecondFormProps {
  onNext: ({
    formValue,
    address,
    dogName,
    numberValue,
  }: {
    formValue: userFormValues
    address: addressValues
    dogName: string
    numberValue: numberFormValues
  }) => void
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const SecondForm = ({ onNext, setStep }: SecondFormProps) => {
  const user = useRecoilValue(userState)
  const [profileImage, setProfileImage] = useState<string>(ProfileImage)
  const [dogName, setDogName] = useState<string>('')
  const [perLimitCheck, setPerLimitCheck] = useState<boolean>(false)

  // modal
  const [showAddressModal, setShowAddressModal] = useRecoilState(addressState)
  const [showDogName, setShowDogName] = useState<boolean>(false)

  //phone
  const [isKindergardenNumberDisabled, setIsKindergardenNumberDisabled] =
    useState<boolean>(false)
  const [isIndividualPhone, setIsIndividualPhone] = useState<boolean>(false)
  const [isKindergardenPhone, setIsKindergardenPhone] = useState<boolean>(false)

  const [address, setAddress] = useState<addressValues>({
    roadAddress: '',
    abbrAddress: '',
    shortAddress: [],
  })

  const [formValue, setFormValue] = useState<userFormValues>({
    mainThumbnailUrl: '',
    profileThumbnailUrl: '',
    businessStartHour: '',
    businessEndHour: '',
    guideMessage: '',
    latitude: null,
    longitude: null,
    reservationAvailabilityOption: '',
    reservationChangeOption: '',
    detailAddress: '',
    dailyPetLimit: 0,
    dogName: '',
  })

  const [numberValue, setNumberValue] = useState<numberFormValues>({
    phoneNumber: '',
    visiblePhoneNumber: {
      userPhoneNumber: '',
      petKinderGardenPhoneNumber: '',
    },
  })

  const handleChangeNumberValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      const checked = e.target.checked

      if (name === 'phoneNumber') {
        if (value) {
          console.log('1')
          setIsKindergardenNumberDisabled(true)
        } else {
          console.log('2')
          setIsKindergardenNumberDisabled(false)
          setIsKindergardenPhone(false)
          setNumberValue((prevNumberValue) => ({
            ...prevNumberValue,
            visiblePhoneNumber: {
              ...prevNumberValue.visiblePhoneNumber,
              petKinderGardenPhoneNumber: '',
            },
          }))
        }

        setNumberValue((prevNumberValue) => ({
          ...prevNumberValue,
          phoneNumber: validate.addPhoneHyphen(value),
        }))
      }

      if (name === 'individualPhoneNumber') {
        console.log('3')
        setIsIndividualPhone(checked)
        setNumberValue((prevNumberValue) => ({
          ...prevNumberValue,
          visiblePhoneNumber: {
            ...prevNumberValue.visiblePhoneNumber,
            userPhoneNumber: checked ? user.phoneNumber : '',
          },
        }))
      }

      if (name === 'kindergardenPhoneNumber') {
        console.log('4')
        setIsKindergardenPhone(checked)
        setNumberValue((prevNumberValue) => ({
          ...prevNumberValue,
          visiblePhoneNumber: {
            ...prevNumberValue.visiblePhoneNumber,
            petKinderGardenPhoneNumber: checked
              ? prevNumberValue.phoneNumber
              : '',
          },
        }))
      }
    },
    [user.phoneNumber, numberValue.phoneNumber],
  )

  const handleChangeFormValue = useCallback(
    (
      e: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = e.target

      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        [name]: value,
      }))
    },
    [],
  )

  const handleDogNameSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setDogName(value)
  }

  // perLimitCheck
  const handlePerLimitCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target

    setPerLimitCheck((checked) => !checked)

    const isChecked = checked ? -1 : 0

    setFormValue({
      ...formValue,
      dailyPetLimit: isChecked,
    })
  }

  // fileUpload
  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      let imgURL = window.URL.createObjectURL(file)
      setProfileImage(imgURL)

      let formData = new FormData()

      formData.append('file', file)
      formData.append('resource_type', 'pet_kindergarden')

      const { fileUrl } = await fileUpload(formData)

      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        mainThumbnailUrl: fileUrl,
        profileThumbnailUrl: fileUrl,
      }))
    }
  }

  const formValueCheck = () => {
    const {
      businessStartHour,
      businessEndHour,
      reservationAvailabilityOption,
      reservationChangeOption,
      dailyPetLimit,
      detailAddress,
    } = formValue

    const { roadAddress } = address

    const { visiblePhoneNumber } = numberValue

    if (
      dogName &&
      businessStartHour &&
      businessEndHour &&
      reservationAvailabilityOption &&
      reservationChangeOption &&
      dailyPetLimit &&
      Object.values(visiblePhoneNumber).some((phone) => phone) &&
      roadAddress &&
      detailAddress
    ) {
      return true
    }

    return false
  }

  const 필수값이입력되었는가 = dogName && formValueCheck()

  const goToPrev = () => {
    setStep(0)
  }

  const onToggleDogSearchBox = () => {
    setShowDogName((prev) => !prev)
  }

  return (
    <>
      <Flex direction="column">
        <MyProfile
          size={120}
          label="유치원 프로필 사진"
          description="5mb 이하"
          mode="upload"
          profileImage={profileImage}
          onChange={handleFileUpload}
          value={formValue.mainThumbnailUrl}
        />

        <Spacing size={24} />

        <TextField
          label="강아지 유치원명"
          placeholder="유치원명 검색"
          name="dogName"
          required
          value={dogName}
          onClick={onToggleDogSearchBox}
          onChange={handleDogNameSearch}
        />

        {showDogName && (
          <SearchDogNameModal
            dogName={dogName}
            setDogName={setDogName}
            setShowDogName={setShowDogName}
          />
        )}

        <Spacing size={24} />

        <TextField
          label="주소"
          placeholder="주소 검색"
          name="address"
          required
          value={address.roadAddress}
          onClick={() => setShowAddressModal(true)}
          onChange={handleChangeFormValue}
          readOnly
        />

        <Spacing size={8} />

        <TextField
          placeholder="상세주소 입력"
          name="detailAddress"
          value={formValue.detailAddress}
          onChange={handleChangeFormValue}
        />

        <Spacing size={24} />

        <TextArea
          label="안내사항"
          placeholder="안내사항의 내용은 유치원 고객(보호자)에게 노출됩니다."
          name="guideMessage"
          value={formValue.guideMessage}
          onChange={handleChangeFormValue}
        />

        <Spacing size={24} />

        <TextField
          label="유치원 전화번호"
          placeholder="유치원 전화번호"
          name="phoneNumber"
          value={numberValue.phoneNumber}
          onChange={handleChangeNumberValue}
        />

        <Spacing size={24} />

        <Flex align="center">
          <Text
            color="gray500"
            typography="14_Md"
            style={{ margin: '0px 2px 8px 0px' }}
          >
            영업시간
          </Text>
          <Text color="red600">*</Text>
        </Flex>
        <Flex align="center" gap="8px">
          <SelectBox
            type="time"
            onChange={handleChangeFormValue}
            value={formValue.businessStartHour}
            name="businessStartHour"
          />
          ~
          <SelectBox
            type="time"
            onChange={handleChangeFormValue}
            value={formValue.businessEndHour}
            name="businessEndHour"
          />
        </Flex>

        <Spacing size={24} />

        <SelectBox
          label="예약 가능 여부"
          name="reservationAvailabilityOption"
          width="100%"
          options={예약여부}
          placeHolder="예약 가능 여부를 선택해주세요"
          required
          value={formValue.reservationAvailabilityOption}
          onChange={handleChangeFormValue}
        />

        <Spacing size={24} />

        <SelectBox
          label="예약 변경 여부"
          name="reservationChangeOption"
          options={예약변경여부}
          placeHolder="예약 변경 여부를 선택해주세요"
          width="100%"
          required
          value={formValue.reservationChangeOption}
          onChange={handleChangeFormValue}
        />

        <Spacing size={24} />

        <TextField
          label="하루 정원 설정"
          placeholder={
            formValue.dailyPetLimit === -1 ? '제한 없음' : '숫자만 입력'
          }
          required
          name="dailyPetLimit"
          value={formValue.dailyPetLimit === -1 ? '' : formValue.dailyPetLimit}
          onChange={handleChangeFormValue}
          disabled={formValue.dailyPetLimit === -1}
          unit="마리"
        />

        <Spacing size={3} />

        <CheckboxField
          label="제한 없음"
          name="notLimit"
          isChecked={perLimitCheck}
          onChange={handlePerLimitCheck}
        />

        <Spacing size={24} />

        <Flex align="center">
          <Text
            color="gray500"
            typography="14_Md"
            style={{ margin: '0px 2px 8px 0px' }}
          >
            고객에게 노출할 연락처
          </Text>
          <Text color="red600">*</Text>
        </Flex>
        <Flex>
          <Text color="gray500" typography="12_Rg">
            1개 이상 선택
          </Text>
        </Flex>

        <Spacing size={8} />
        <CheckboxField
          label="휴대폰 번호"
          disabled={!user.phoneNumber}
          isChecked={isIndividualPhone}
          onChange={handleChangeNumberValue}
          name="individualPhoneNumber"
        />
        <Spacing size={11} />
        <CheckboxField
          label="유치원 전화번호"
          disabled={!numberValue.phoneNumber}
          isChecked={isKindergardenPhone}
          onChange={handleChangeNumberValue}
          name="kindergardenPhoneNumber"
        />

        <Spacing size={64} />

        <Flex justify="space-between">
          <Button size="small" color="normal" onClick={goToPrev}>
            이전
          </Button>
          <Button
            size="small"
            disabled={!필수값이입력되었는가}
            onClick={() => onNext({ dogName, formValue, address, numberValue })}
          >
            완료
          </Button>
        </Flex>
      </Flex>
      {showAddressModal && <Address setAddressValue={setAddress} />}
    </>
  )
}

export default SecondForm
