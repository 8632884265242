import { createContext, useCallback, useContext, useState } from 'react'

import NewToast from '@common/NewToast'

interface ToastContextValue {
  (message: string, duration?: number): void
}

interface DefaultValues {
  message: string | null
  duration: number
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined)

const defaultValues = {
  message: null,
  duration: 0,
}

export const ToastContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [toast, setToast] = useState<DefaultValues>(defaultValues)

  const showToast = useCallback((message: string, duration = 2000) => {
    setToast((prevState) => ({
      ...prevState,
      message,
      duration,
    }))
  }, [])

  const closeToast = () => {
    setToast(defaultValues)
  }

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toast.message && (
        <NewToast
          message={toast.message}
          duration={toast.duration}
          onClose={closeToast}
        />
      )}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within a ToastContextProvider')
  }

  return context
}
