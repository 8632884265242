const ResetIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12C4.6 12 3.36111 11.575 2.28333 10.725C1.20556 9.875 0.505556 8.78889 0.183333 7.46667C0.138889 7.3 0.172222 7.14722 0.283333 7.00833C0.394444 6.86944 0.544444 6.78889 0.733333 6.76667C0.911111 6.74445 1.07222 6.77778 1.21667 6.86667C1.36111 6.95556 1.46111 7.08889 1.51667 7.26667C1.78333 8.26667 2.33333 9.08333 3.16667 9.71667C4 10.35 4.94444 10.6667 6 10.6667C7.3 10.6667 8.40278 10.2139 9.30833 9.30833C10.2139 8.40278 10.6667 7.3 10.6667 6C10.6667 4.7 10.2139 3.59722 9.30833 2.69167C8.40278 1.78611 7.3 1.33333 6 1.33333C5.23333 1.33333 4.51667 1.51111 3.85 1.86667C3.18333 2.22222 2.62222 2.71111 2.16667 3.33333H3.33333C3.52222 3.33333 3.68056 3.39722 3.80833 3.525C3.93611 3.65278 4 3.81111 4 4C4 4.18889 3.93611 4.34722 3.80833 4.475C3.68056 4.60278 3.52222 4.66667 3.33333 4.66667H0.666667C0.477778 4.66667 0.319444 4.60278 0.191667 4.475C0.0638889 4.34722 0 4.18889 0 4V1.33333C0 1.14444 0.0638889 0.986111 0.191667 0.858333C0.319444 0.730556 0.477778 0.666667 0.666667 0.666667C0.855556 0.666667 1.01389 0.730556 1.14167 0.858333C1.26944 0.986111 1.33333 1.14444 1.33333 1.33333V2.23333C1.9 1.52222 2.59167 0.972222 3.40833 0.583333C4.225 0.194444 5.08889 0 6 0C6.83333 0 7.61389 0.158333 8.34167 0.475C9.06944 0.791667 9.70278 1.21944 10.2417 1.75833C10.7806 2.29722 11.2083 2.93056 11.525 3.65833C11.8417 4.38611 12 5.16667 12 6C12 6.83333 11.8417 7.61389 11.525 8.34167C11.2083 9.06944 10.7806 9.70278 10.2417 10.2417C9.70278 10.7806 9.06944 11.2083 8.34167 11.525C7.61389 11.8417 6.83333 12 6 12Z"
        fill="#C8CEDA"
      />
    </svg>
  )
}

export default ResetIcon
