import { CustomerLists } from '@/types/customer'
import Flex from '@common/Flex'
import Pagination from '@components/pagination/Pagination'
import CustomerListTable from '@components/customer/CustomerListTable'

interface CustomerListProps {
  currentTab: number
  customerList: CustomerLists
  currentPage: number
  setCurrentPage: (page: number) => void
  setOffset: (offset: number) => void
}

const CustomerList = ({
  currentTab,
  customerList,
  currentPage,
  setCurrentPage,
  setOffset,
}: CustomerListProps) => {
  if (!customerList) return null

  const { next, previous, limit, count } = customerList

  return (
    <>
      <Flex direction="column">
        {currentTab === 0 ? (
          <CustomerListTable
            currentTab={currentTab}
            customerList={customerList}
          />
        ) : (
          <CustomerListTable customerList={customerList} />
        )}

        {customerList.results.length ? (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            next={next}
            previous={previous}
            count={count}
            setOffset={setOffset}
          />
        ) : null}
      </Flex>
    </>
  )
}

export default CustomerList
