import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { useEffect, useState } from 'react'

import { colors } from '@styles/colors'
import Text from '@common/Text'

interface ToastProps {
  message: string
  duration?: number
  show: boolean
  onClose: () => void
}

const Toast = ({ message, duration = 2000, show, onClose }: ToastProps) => {
  const [isVisible, setIsVisible] = useState(show)

  useEffect(() => {
    if (show) {
      setIsVisible(true)
      const timer = setTimeout(() => {
        setIsVisible(false)
        onClose()
      }, duration)

      return () => clearTimeout(timer)
    }
  }, [show])

  return isVisible ? (
    <ToastMessage>
      <Text typography="14_Md" color="white">
        {message}
      </Text>
    </ToastMessage>
  ) : null
}

export default Toast

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`

const ToastMessage = styled.div`
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 18px 40px;
  background-color: ${colors.gray600};
  border-radius: 27.5px;
  text-align: center;
  animation: ${fadeInOut} 2s;
`
