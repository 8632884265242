import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRecoilValue } from 'recoil'

import { getCustomerTickets } from '@apis/calendar/calendar'
import { getKindergardenInfo } from '@apis/user/user'
import { Customer, Ticket, ReservationRequest } from '@/types/calendar'
import Spacing from '@common/Spacing'
import CustomerSearch from '@components/schedule/DirectlyRegister/CustomerSearch'
import SelectTicketType from '@components/schedule/DirectlyRegister/SelectTicketType'
import SelectTimeTicket from '@components/schedule/DirectlyRegister/SelectTimeTicket'
import SelectAllDayTicket from '@components/schedule/DirectlyRegister/SelectAllDayTicket'
import SelectHotelTicket from '@components/schedule/DirectlyRegister/SelectHotelTicket'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

interface DirectlyRegisterProps {
  setFinalData: (reservation: ReservationRequest) => void
  setCanRegist: (enabled: boolean) => void
}

const DirectlyRegister = ({
  setFinalData,
  setCanRegist,
}: DirectlyRegisterProps) => {
  const petKindergardenId = useRecoilValue(petKindergardenIdState)

  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null)
  const [selectedType, setSelectedType] = useState<string>('')
  const [selectedUsageTime, setSelectedUsageTime] = useState<string>('')
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null,
  )
  const [startHour, setStartHour] = useState('')
  const [selectedStay, setSelectedStay] = useState('')
  const [isTimeExceeded, setIsTimeExceeded] = useState<boolean>(false)

  const { data: petKindergardenInfo } = useQuery({
    queryKey: ['pet-kindergardens', petKindergardenId],
    queryFn: () => getKindergardenInfo(petKindergardenId as number),
    enabled: !!petKindergardenId,
  })

  const { data: tickets = {} } = useQuery({
    queryKey: ['getCustomerTickets', selectedCustomer?.customer.id],
    queryFn: () =>
      selectedCustomer
        ? getCustomerTickets(
            petKindergardenId as number,
            selectedCustomer.customer.id,
          )
        : Promise.resolve({ time: [], allDay: [], hotel: [] }),
    enabled: !!selectedCustomer,
  })

  useEffect(() => {
    const isFormValid =
      !!selectedCustomer &&
      ((selectedType === 'time' && !!startHour && !!selectedTicket) ||
        (selectedType === 'allDay' && !!selectedTicket) ||
        (selectedType === 'hotel' && !!selectedStay))

    setCanRegist(isFormValid)
  }, [selectedCustomer, selectedTicket, selectedType, startHour, selectedStay])

  useEffect(() => {
    setSelectedTicket(null)
    setSelectedUsageTime('')
    setStartHour('')
    setSelectedStay('')
    setIsTimeExceeded(false)

    setFinalData({
      customerTicketIds: [],
      customerId: selectedCustomer?.customer.id || 0,
      customerPetId: selectedCustomer?.id || 0,
      reservedAt: '',
      endAt: '',
    })
  }, [selectedType])

  return (
    <Wrapper>
      <CustomerSearch
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        setSelectedType={setSelectedType}
        setSelectedTicket={setSelectedTicket}
        setSelectedUsageTime={setSelectedUsageTime}
      />

      <Spacing size={40} />

      {selectedCustomer && (
        <SelectTicketType
          tickets={tickets}
          selectedType={selectedType}
          selectedCustomer={selectedCustomer}
          setSelectedType={setSelectedType}
        />
      )}

      <Spacing size={12} />

      {selectedType === 'time' && (
        <SelectTimeTicket
          tickets={tickets}
          selectedUsageTime={selectedUsageTime}
          selectedCustomer={selectedCustomer}
          selectedTicket={selectedTicket}
          startHour={startHour}
          isTimeExceeded={isTimeExceeded}
          petKindergardenInfo={petKindergardenInfo}
          setIsTimeExceeded={setIsTimeExceeded}
          setFinalData={setFinalData}
          setSelectedUsageTime={setSelectedUsageTime}
          setStartHour={setStartHour}
          setSelectedTicket={setSelectedTicket}
        />
      )}

      {selectedType === 'allDay' && (
        <SelectAllDayTicket
          tickets={tickets}
          selectedTicket={selectedTicket}
          selectedCustomer={selectedCustomer}
          setSelectedTicket={setSelectedTicket}
          setFinalData={setFinalData}
        />
      )}

      {selectedType === 'hotel' && (
        <SelectHotelTicket
          tickets={tickets}
          selectedCustomer={selectedCustomer}
          selectedStay={selectedStay}
          setSelectedStay={setSelectedStay}
          setFinalData={setFinalData}
        />
      )}
    </Wrapper>
  )
}

export default DirectlyRegister

const Wrapper = styled.div`
  min-height: 208px;
  position: relative;
  max-width: 320px;
  width: 100%;
`
