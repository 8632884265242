import { AxiosError } from 'axios'

import { Method } from '@utils/axios'
import { privateAxios } from '@utils/newAxios'

export interface ErrorResponseData {
  message?: string
}

// 파일 업로드
export const fileUpload = async (formData: FormData) => {
  try {
    const response = await privateAxios(Method.POST, 'files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  } catch (error) {
    console.log('error', error)
  }
}

// 엑셀 파일 업로드
export const excelUpload = async (
  pet_kindergarden_id: number,
  formData: any,
) => {
  const csvFile = formData

  try {
    const response = await privateAxios(
      Method.POST,
      `pet-kindergardens/${pet_kindergarden_id}/customers/batch-register`,
      csvFile,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return response.data
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponseData>

    throw axiosError
  }
}
