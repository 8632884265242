import Text from '@common/Text'
import Flex from '@common/Flex'
import Spacing from '@common/Spacing'

const FormHead = ({ complete }: { complete?: boolean }) => {
  return (
    <Flex direction="column" align="center">
      <Text typography="16_Sb" color="yellow600">
        회원가입
      </Text>

      <Spacing size={12} />

      <Text
        typography="24_Sb"
        color="gray600"
        textAlign="center"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {complete
          ? '멍매니저 파트너가\n되신 것을 축하합니다!'
          : '멍매니저 파트너 등록'}
      </Text>

      <Spacing size={14} />

      <Text
        typography="14_Rg"
        color="gray500"
        textAlign="center"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {complete
          ? '아래 내용을 차근차근 따라오시면\n쉽게 예약/등원 관리를 하실 수 있어요!'
          : '안녕하세요! 사장님 멍매니저 파트너 등록을 위해\n기본 정보를 입력해 주세요.'}
      </Text>

      <Spacing size={64} />
    </Flex>
  )
}

export default FormHead
