import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { colors } from '@styles/colors'
import { Customer } from '@/types/calendar'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Button from '@common/Button'

interface NoTicketProps {
  selectedCustomer: Customer
}

const NoTicket = ({ selectedCustomer }: NoTicketProps) => {
  const navigate = useNavigate()

  return (
    <Empty justify="center" align="center" direction="column" gap="16px">
      <Text typography="14_Rg" color="gray600" css={{ textAlign: 'center' }}>
        보유 중인 이용권이 없습니다.
        <br />
        고객에게 이용권을 먼저 부여해 주세요.
      </Text>
      <Button
        onClick={() =>
          navigate(`/main/customers/${selectedCustomer?.customer?.id}`)
        }
        full
      >
        고객 정보 페이지 이동
      </Button>
    </Empty>
  )
}

export default NoTicket

const Empty = styled(Flex)`
  border: 1px solid ${colors.gray200};
  border-radius: 10px;
  padding: 20px;
`
