import { useQuery } from '@tanstack/react-query'
import { useSetRecoilState } from 'recoil'
import { useEffect } from 'react'

import { getKindergartenProfile } from '@apis/user/user'
import { QUERY_KEY } from '@constants/queryKey'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

type Kindergarden = {
  id: number
  name: string
  profileThumbnailUrl: string
}

type User = {
  name: string
}

interface Profile {
  petKindergarden: Kindergarden
  user: User
}

export const useProfile = () => {
  const setPetKindergardenId = useSetRecoilState(petKindergardenIdState)

  const { data: profile } = useQuery({
    queryKey: [QUERY_KEY.PROFILE],
    queryFn: getKindergartenProfile,
    gcTime: 1000 * 60 * 60,
    staleTime: 1000 * 60,
  })

  useEffect(() => {
    if (profile) {
      setPetKindergardenId(profile.petKindergarden.id)
    }
  }, [profile, setPetKindergardenId])

  return { profile } as { profile: Profile }
}
