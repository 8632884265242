const SideBarGuest = ({ isOn = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 19H12H18V18.4584C17.9894 17.697 17.7214 16.95 17.221 16.2872C16.7206 15.6244 16.0041 15.0674 15.1386 14.6683C14.273 14.2692 13.2865 14.041 12.2714 14.005C12.1809 14.0018 12.0904 14.0001 12 14C11.9096 14.0001 11.8191 14.0018 11.7286 14.005C10.7135 14.041 9.727 14.2692 8.86143 14.6683C7.99587 15.0674 7.27943 15.6244 6.77903 16.2872C6.27864 16.95 6.01061 17.697 6 18.4584V19Z"
        stroke={`${isOn ? '#272D3A' : '#C8CEDA'}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SideBarGuest
