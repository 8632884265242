import styled from '@emotion/styled'

interface DimmedProps {
  children: React.ReactNode
  modalRef?: React.ForwardedRef<HTMLDivElement>
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const Dimmed = ({ children, modalRef, onClick }: DimmedProps) => {
  return (
    <DimContainer ref={modalRef} onClick={onClick}>
      {children}
    </DimContainer>
  )
}

export default Dimmed

const DimContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
`
