import { colors } from '@styles/colors'

interface CheckRadioIconProps {
  isSelected?: boolean
}

const CheckRadioIcon = ({ isSelected }: CheckRadioIconProps) => {
  const isFillSelect = isSelected ? `${colors.gray600}` : `${colors.white}`

  const isCircleSelect = isSelected ? `${colors.gray600}` : `${colors.gray300}`

  const pathSelect = isSelected ? `${colors.white}` : `${colors.gray300}`

  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill={isFillSelect}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12.5"
          cy="12"
          r="9"
          stroke={isCircleSelect}
          strokeWidth="2"
        />
        <path
          d="M8.5 12L11.5 15L16.5 10"
          stroke={pathSelect}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default CheckRadioIcon
