import { useState } from 'react'
import styled from '@emotion/styled'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import Dimmed from '@common/Dimmed'
import Flex from '@common/Flex'
import Spacing from '@common/Spacing'
import TextField from '@common/TextField'
import Text from '@common/Text'
import Button from '@common/Button'
import Container from '@common/Container'
import { colors } from '@styles/colors'
import { validate } from '@utils/validation'
import { useHandleDirty } from '@utils/dirty'
import { createKindergardenCustomer } from '@apis/customer/customer'
import CancelIcon from '@components/icon/CancelIcon'
import { QUERY_KEY } from '@constants/queryKey'
import { useToast } from '@contexts/ToastContext'

export interface Info {
  name: string
  phoneNumber: string
  dogName: string[]
}

export type Error = Omit<Info, 'dogName'> & { dogName?: string }

interface CustomerModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  id: number
}

const CustomerModal = ({ setShowModal, id }: CustomerModalProps) => {
  const toast = useToast()
  const [tag, setTag] = useState<string>('')
  const { handleDirty, dirty } = useHandleDirty()
  const queryClient = useQueryClient()

  const [addCustomerInfo, setAddCustomerInfo] = useState<Info>({
    name: '',
    phoneNumber: '',
    dogName: [],
  })

  const { mutate: createCustomer } = useMutation({
    mutationFn: ({ addCustomerInfo }: { addCustomerInfo: Info }) => {
      return createKindergardenCustomer({
        pet_kindergarden_id: id as number,
        name: addCustomerInfo.name,
        phoneNumber: addCustomerInfo.phoneNumber,
        pets:
          addCustomerInfo.dogName.length === 0
            ? [tag]
            : addCustomerInfo.dogName,
      })
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.CUSTOMER_LIST] })
      toast('고객 정보가 등록되었습니다.')
    },

    onError: () => {
      toast('고객 정보 등록에 실패했습니다.')
    },
  })

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCustomerInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === 'phoneNumber') {
      const phoneNumber = value.replace(/\D/g, '')

      const formattedPhoneNumber = phoneNumber.replace(
        /(\d{3})(\d{4})(\d{4})/,
        '$1-$2-$3',
      )
      setAddCustomerInfo({
        ...addCustomerInfo,
        phoneNumber: formattedPhoneNumber,
      })
    } else {
      setAddCustomerInfo({
        ...addCustomerInfo,
        [name]: value,
      })
    }
  }

  const handleHashtag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setTag(value.trim())
  }

  const onAddTag = (e: any) => {
    const { value } = e.target

    if (e.keyCode === 32) {
      setAddCustomerInfo({
        ...addCustomerInfo,
        dogName: [...addCustomerInfo.dogName, value],
      })

      setTag('')
    }
  }

  const onDeleteTag = (id: number) => {
    const newTag = addCustomerInfo.dogName.filter((_, index) => index !== id)

    setAddCustomerInfo({
      ...addCustomerInfo,
      dogName: newTag,
    })
  }

  const handleFormValidation = (formValues: Info) => {
    let error: Partial<Error> = {}

    if (!validate.isPhone(formValues.phoneNumber)) {
      error.phoneNumber = '휴대폰 번호 형식이 올바르지 않습니다.'
    }

    if (!formValues.phoneNumber) {
      error.phoneNumber = '휴대폰 번호를 입력해주세요.'
    }

    if (!formValues.name) {
      error.name = '이름을 입력해주세요.'
    }

    if (!formValues.dogName.length && !tag.length) {
      error.dogName = '강아지 이름을 입력해주세요.'
    }

    return error
  }

  const handleChangeDirty = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDirty(e.target.name)
  }

  const errorCheck = handleFormValidation(addCustomerInfo)

  const 필수값이입력되었는가 = Boolean(Object.values(errorCheck).length)

  const onSubmit = () => {
    createCustomer({ addCustomerInfo })
    setShowModal(false)
  }

  return (
    <>
      <Dimmed>
        <Container>
          <ModalWrapper direction="column">
            <Text typography="24_Sb" textAlign="center">
              고객 등록
            </Text>

            <Spacing size={40} />
            <Flex direction="column">
              <TextField
                placeholder="보호자 이름"
                name="name"
                onChange={handleCustomerInfo}
                value={addCustomerInfo.name}
                hasError={Boolean(dirty.name) && Boolean(errorCheck.name)}
                errorMessage={Boolean(dirty.name) && errorCheck.name}
                onBlur={handleChangeDirty}
              />

              <Spacing size={8} />

              <TextField
                placeholder="휴대폰 번호"
                name="phoneNumber"
                value={addCustomerInfo.phoneNumber}
                onChange={handleCustomerInfo}
                hasError={
                  Boolean(dirty.phoneNumber) && Boolean(errorCheck.phoneNumber)
                }
                errorMessage={
                  Boolean(dirty.phoneNumber) && errorCheck.phoneNumber
                }
                onBlur={handleChangeDirty}
              />

              <Spacing size={8} />

              <TextField
                placeholder="강아지 이름"
                name="dogName"
                onChange={handleHashtag}
                onKeyUp={onAddTag}
                value={tag}
                hasError={Boolean(dirty.dogName) && Boolean(errorCheck.dogName)}
                errorMessage={Boolean(dirty.dogName) && errorCheck.dogName}
                onBlur={handleChangeDirty}
              />

              <Text typography="14_Rg" color="gray500" css={{ marginTop: 8 }}>
                강아지 여러 마리일 경우 ‘스페이스바’ 로 구분
              </Text>

              <TagFlex>
                {addCustomerInfo.dogName.map((tag, index) => (
                  <TagText key={index} onClick={() => onDeleteTag(index)}>
                    {tag}
                    <CancelIcon />
                  </TagText>
                ))}
              </TagFlex>
            </Flex>

            <Spacing size={52} />

            <Flex gap={10}>
              <Button color="normal" size="mediumSmall" onClick={closeModal}>
                <Text typography="14_Md" color="gray400">
                  취소
                </Text>
              </Button>
              <Button
                color="primary"
                size="mediumSmall"
                disabled={필수값이입력되었는가}
                onClick={onSubmit}
              >
                <Text typography="14_Md" color="gray100">
                  등록하기
                </Text>
              </Button>
            </Flex>
          </ModalWrapper>
        </Container>
      </Dimmed>
    </>
  )
}

const ModalWrapper = styled(Flex)`
  padding: 56px 40px 48px;
  width: 312px;
`

const TagFlex = styled(Flex)`
  margin-top: 8px;
  flex-wrap: wrap;
`

const TagText = styled(Text)`
  width: max-content;
  font-size: 12px;
  background-color: ${colors.gray100};
  padding: 4px 8px;
  margin: 0 4px 0 0;
  border-radius: 4px;

  svg {
    margin-left: 4px;
    cursor: pointer;
  }
`

export default CustomerModal
