import React from 'react'
import ReactDOM from 'react-dom/client'
import { Global } from '@emotion/react'
import { RecoilRoot } from 'recoil'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import App from '@/App'
import globalStyles from '@styles/globalStyles'
import Loading from '@common/Loading'
import { AlertContextProvider } from '@contexts/AlertContext'
import { ToastContextProvider } from '@contexts/ToastContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 5,
      throwOnError: true,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Global styles={globalStyles} />
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ToastContextProvider>
          <AlertContextProvider>
            <Loading>
              <App />
            </Loading>
          </AlertContextProvider>
        </ToastContextProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
)
