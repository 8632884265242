import axios from 'axios'
import Cookies from 'js-cookie'

import { Method } from '@utils/axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const secretAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('accessToken')}`,
  },
})

const commonAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

export const privateAxios = async (
  method: Method,
  url: string,
  ...rest: { [key: string]: any }[]
) => {
  const res = await secretAxios[method](url, ...rest)

  return res.data
}

export const publicAxios = async (
  method: Method,
  url: string,
  ...rest: { [key: string]: any }[]
) => {
  const res = await commonAxios[method](url, ...rest)

  return res.data
}

secretAxios.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      const refreshToken = Cookies.get('refreshToken')

      try {
        const response = await axios.post(`auth/jwt/refresh`, {
          refresh: refreshToken,
        })

        Cookies.set('accessToken', response.data.data.accessToken)
        originalRequest.headers.Authorization = `Bearer ${response.data.data.accessToken}`

        return axios(originalRequest)
      } catch (refreshError) {
        console.log('refreshError', refreshError)

        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  },
)
