import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import Flex from '@common/Flex'
import Calendar from '@components/schedule/calendar'
import ListByDate from '@components/schedule/ListByDate'
import { useMonthlyReservations } from '@hooks/schedule/useMonthlyReservations'
import { useDailyReservations } from '@hooks/schedule/useDailyReservations'
import { selectedDateState } from '@atoms/selectedDate'

const SchedulePage = () => {
  const today = new Date()
  const todayString = today.toISOString().split('T')[0]
  const selectedDate = useRecoilValue(selectedDateState)
  const [currentMonth, setCurrentMonth] = useState<Date>(today)

  const { data: monthlyReservations } = useMonthlyReservations(currentMonth)
  const { data: dailyReservations } = useDailyReservations(selectedDate)

  const selectedDateString = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`
  const isPastDate = new Date(selectedDateString) < new Date(todayString)

  return (
    <Flex>
      <Calendar
        dailyReservations={monthlyReservations?.dailyReservations}
        dayOffs={monthlyReservations?.dayOffs}
        koreaSpecialDays={monthlyReservations?.koreaSpecialDays}
        setCurrentMonth={setCurrentMonth}
      />

      <ListByDate
        dayOffs={monthlyReservations?.dayOffs}
        dailyReservations={dailyReservations}
        isPastDate={isPastDate}
      />
    </Flex>
  )
}

export default SchedulePage
