import { OptionType } from '@constants/signup'

export const TAB_TITLE = ['고객정보', '비활성고객']
export const USER_DETAIL_TAB_TITLE = ['고객 상세 정보']

export const USER_TABLE_HEAD: string[] = [
  '보호자 이름',
  '휴대폰 번호',
  '강아지',
  '보유 이용권',
  '메모',
  '최근 예약일',
  '고객 등록일',
]

export const USER_DETAIL_TICKET_ADD_TABLE_HEAD: string[] = [
  '보유 이용권',
  '시간',
  '횟수(박)',
  '사용기간',
  '금액',
  '등록일',
  '만료일',
  '상태',
]

export const USER_DETAIL_TICKET_USED_TABLE_HEAD: string[] = [
  '보유 이용권',
  '시간',
  '횟수(박)',
  '사용횟수',
  '잔여횟수',
  '출석 여부',
  '예약 일자',
  '만료일',
  '비고',
]

export const USER_INACTIVE_TABLE_HEAD: string[] = [
  '보호자 이름',
  '휴대폰 번호',
  '강아지',
  '메모',
  '최근 예약일',
  '고객 등록일',
  '활성 상태',
]

export const 보유이용권: OptionType[] = [{ value: '', label: '보유이용권' }]
