import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import { TicketsData } from '@/types/ticket'
import ticketTime from '@assets/images/ticket-time.svg'
import ticketAllDay from '@assets/images/ticket-allday.svg'
import ticketHotel from '@assets/images/ticket-hotel.svg'
import Flex from '@common/Flex'
import Text from '@common/Text'

interface TicketsProps {
  tickets?: TicketsData
  showModal: (
    ticketId: number,
    ticketType: string,
    ticketUsageTimeCount: number,
    ticketUsageCount: number,
  ) => void
}

const Tickets = ({ tickets, showModal }: TicketsProps) => {
  return (
    <>
      {tickets?.data?.map((ticket: any) => (
        <TicketBox isDeleted={ticket.isDeleted} key={ticket.id}>
          <img
            src={
              ticket.ticketType === '시간'
                ? ticketTime
                : ticket.ticketType === '종일'
                  ? ticketAllDay
                  : ticketHotel
            }
            alt=""
          />
          <TicketText>
            <Flex direction="column" gap="8px" css={{ maxWidth: '190px' }}>
              <Text
                typography="24_Sb"
                color="gray600"
                css={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {ticket.ticketType === '시간'
                  ? `${ticket.usageTime}시간 • ${ticket.usageCount}회`
                  : `${ticket.ticketType} ${ticket.usageCount}회`}
              </Text>
              <Text typography="12_Rg" color="gray500">
                등록 후 {ticket.usagePeriodInDaysCount}일 내 사용
                <br />
                금액: {ticket.price.toLocaleString()}원
              </Text>
            </Flex>

            {!ticket.isDeleted && (
              <Flex align="end">
                <Text
                  typography="12_Md"
                  color="gray300"
                  onClick={() =>
                    showModal(
                      ticket.id,
                      ticket.ticketType,
                      ticket.usageTime,
                      ticket.usageCount,
                    )
                  }
                  css={{ cursor: 'pointer' }}
                >
                  삭제
                </Text>
              </Flex>
            )}
          </TicketText>
        </TicketBox>
      ))}
    </>
  )
}

export default Tickets

const TicketBox = styled(Flex)<{ isDeleted?: boolean }>`
  border: 1px solid ${colors.gray200};
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
  opacity: ${(props) => (props.isDeleted ? 0.3 : 1)};
  width: 347px;
  box-sizing: border-box;
`

const TicketText = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding-left: 24px;
  padding-right: 20px;
`
