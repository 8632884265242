import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteTicket } from '@apis/ticket/ticket'

interface useDeleteTicketProps {
  petKindergardenId: number
  onSuccess: () => void
  onError: () => void
}

export const useDeleteTicket = ({
  petKindergardenId,
  onSuccess,
  onError,
}: useDeleteTicketProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (ticketId: number) => deleteTicket(petKindergardenId, ticketId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['pet-kindergardens'],
      })
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}
