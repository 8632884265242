import { colors } from '@styles/colors'

const RightArrow = ({ isDisable }: { isDisable: boolean }) => {
  const hasColor = isDisable ? `${colors.gray600}` : `${colors.gray300}`
  return (
    <>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9L5 5L1 1"
          stroke={hasColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default RightArrow
