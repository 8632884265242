const CalendarHotelIcon = ({ isActive = false, size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.099 14.6511C19.0262 15.4119 17.7261 15.8574 16.3248 15.8574C12.6462 15.8574 9.66406 12.7873 9.66406 9.00021C9.66406 6.47992 10.9848 4.2772 12.9526 3.08556C12.557 3.02915 12.1529 3 11.7422 3C6.91403 3 3 7.02944 3 12C3 16.9706 6.91403 21 11.7422 21C15.6742 21 18.9998 18.3277 20.099 14.6511Z"
        fill={isActive ? '#8BAAF8' : '#C8CEDA'}
      />
    </svg>
  )
}

export default CalendarHotelIcon
