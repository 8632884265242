import Cookies from 'js-cookie'

import { KindergardenUpdateInfo } from '@/types/kindergarden'
import { Method } from '@utils/axios'
import { privateAxios, publicAxios } from '@utils/newAxios'
import { AxiosError } from 'axios'
import { ErrorResponseData } from '../upload/fileUpload'

// 카카오 로그인을 통해 받은 code를 이용하여 token을 받아오는 API
export const getToken = async (code: string) => {
  try {
    const response = await publicAxios(
      Method.GET,
      `auth/kakao/callback?code=${code}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`,
    )

    const { accessToken, refreshToken } = response.data

    Cookies.set('accessToken', accessToken)
    Cookies.set('refreshToken', refreshToken)

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// accessToken을 이용하여 유저 정보를 받아오는 API
export const getUser = async (accessToken: string) => {
  try {
    const response = await privateAxios(Method.GET, `users/profile`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 카카오 유저 정보 update API
export const updateUserInfo = async (name: string, email: string) => {
  try {
    const response = await privateAxios(Method.PATCH, `users/profile`, {
      name,
      email,
    })

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 유치원 정보 생성 API
export const createKindergartenInfo = async (formValues: any) => {
  try {
    const response = await privateAxios(
      Method.POST,
      'pet-kindergardens',
      formValues,
    )

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 반려동물 유치원 프로필 조회
export const getKindergartenProfile = async () => {
  try {
    const response = await privateAxios(Method.GET, 'pet-kindergardens/profile')

    return response.data
  } catch (e) {
    console.error('error', e)
  }
}

// 반려동물 유치원 정보 조회
export const getKindergardenInfo = async (id: number) => {
  try {
    const response = await privateAxios(Method.GET, `pet-kindergardens/${id}`)

    return response.data
  } catch (e) {
    console.log('error', e)
  }
}

// 반려동물 유치원 정보 수정
export const updateKindergardenInfo = async (
  id: number,
  formValues: KindergardenUpdateInfo,
) => {
  try {
    const response = await privateAxios(
      Method.PUT,
      `pet-kindergardens/${id}`,
      formValues,
    )

    return response.data
  } catch (error) {
    const axiosError = error as AxiosError<any>

    throw axiosError
  }
}
