const NoListIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6.27273L18.2273 3L12.5005 8.72676L6.77395 3.0002L3.50122 6.27292L9.22779 11.9995L3.5 17.7273L6.77273 21L12.5005 15.2722L18.2285 21.0002L21.5012 17.7275L15.7732 11.9995L21.5 6.27273Z"
        fill="#F49396"
      />
    </svg>
  )
}

export default NoListIcon
