import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  USER_DETAIL_TAB_TITLE,
  USER_DETAIL_TICKET_ADD_TABLE_HEAD,
  USER_DETAIL_TICKET_USED_TABLE_HEAD,
} from '@constants/userInfo'
import { colors } from '@styles/colors'
import { Pet, UserDetailInfo } from '@/types/userInfo'
import Button from '@common/Button'
import Flex from '@common/Flex'
import Modal from '@common/Modal'
import Spacing from '@common/Spacing'
import Tab from '@common/Tab'
import Table from '@common/Table'
import Text from '@common/Text'
import TextArea from '@common/Textarea'
import TextField from '@common/TextField'
import { Error } from '@components/customer/CustomerModal'
import { validate } from '@utils/validation'
import { useProfile } from '@hooks/useProfile'
import {
  createKindergardenCustomersTicket,
  getKindergardenCustomersDetail,
  getKindergardenCustomersTicket,
  getKindergardenCustomersTicketList,
  getKindergardenCustomersTicketLog,
  updateKindergardenCustomer,
} from '@apis/customer/customer'
import { generateDate } from '@utils/generateDate'
import { useActive } from '@hooks/useActive'
import { QUERY_KEY } from '@constants/queryKey'
import {
  CustomerTicket,
  CustomerTicketAddLists,
  CustomerTicketUsedLists,
} from '@/types/customer'
import addDelimiter from '@utils/addDelimiter'
import Pagination from '@components/pagination/Pagination'
import TicketListRow from '@components/customer/TicketListRow'
import CancelIcon from '@icon/CancelIcon'
import NoListIcon from '@icon/NoListIcon'
import NoList from '@components/customer/NoList'
import { useToast } from '@contexts/ToastContext'

const yellow = require('@assets/images/yellow.png')
const red = require('@assets/images/red.png')
const blue = require('@assets/images/blue.png')

const CustomerDetail = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const toast = useToast()
  const queryClient = useQueryClient()

  const [activeModal, setActiveModal] = useState(false)
  const [ticketModal, setTicketModal] = useState(false)
  const [tag, setTag] = useState('')
  const [ticketUsedOffset, setTicketUsedOffset] = useState(0)
  const [ticketAddOffset, setTicketAddOffset] = useState(0)
  const [currentTicketUsedPage, setCurrentTicketUsedPage] = useState<number>(1)
  const [currentTicketAddPage, setCurrentTicketAddPage] = useState<number>(1)
  const [customerInfo, setCustomerInfo] = useState<UserDetailInfo>({
    name: '',
    phoneNumber: '',
    pets: [],
    memo: '',
  })

  const [deletePetName, setDeletePetName] = useState<string[]>([])
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null)

  const { profile } = useProfile()

  const { data: detail } = useQuery({
    queryKey: [QUERY_KEY.CUSTOMER_DETAIL, id],
    queryFn: () =>
      getKindergardenCustomersDetail(
        profile?.petKindergarden?.id,
        parseInt(id),
      ),
    enabled: !!profile,
  })

  useEffect(() => {
    if (detail) {
      setCustomerInfo({
        name: detail.name,
        phoneNumber: detail.phoneNumber,
        pets: detail.customerPets,
        memo: detail.memo,
      })
    }
  }, [detail])

  const { data: ticketList } = useQuery({
    queryKey: [QUERY_KEY.CUSTOMER_TICKET_LIST],
    queryFn: () =>
      getKindergardenCustomersTicketList(profile?.petKindergarden?.id),
    enabled: !!profile,
  })

  const { data: ticketAddList } = useQuery<CustomerTicketAddLists>({
    queryKey: [QUERY_KEY.CUSTOMER_TICKET_ADD_LIST, id, ticketAddOffset],
    queryFn: () =>
      getKindergardenCustomersTicket(
        profile?.petKindergarden?.id,
        parseInt(id),
        ticketAddOffset,
      ),
    enabled: !!profile,
  })

  const { data: ticketUsedList } = useQuery<CustomerTicketUsedLists>({
    queryKey: [QUERY_KEY.CUSTOMER_TICKET_USED_LIST, id, ticketUsedOffset],
    queryFn: () =>
      getKindergardenCustomersTicketLog(
        profile?.petKindergarden?.id,
        parseInt(id),
        ticketUsedOffset,
      ),
    enabled: !!profile,
  })

  const { mutate: updateActive } = useActive({
    kindergardenId: profile?.petKindergarden?.id,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CUSTOMER_DETAIL, id],
      })

      const isActive = detail?.isActive ? '비활성화' : '활성화'

      toast(`고객이 ${isActive} 되었습니다.`)
    },
    onError: () => {
      toast('고객 상태 변경에 실패했습니다.')
    },
  })

  const { mutate: updateDetail } = useMutation({
    mutationFn: () => {
      return updateKindergardenCustomer({
        pet_kindergarden_id: profile?.petKindergarden?.id,
        customer_id: parseInt(id),
        name: customerInfo.name,
        phoneNumber: customerInfo.phoneNumber,
        petsToAdd: filterPetAdded(customerInfo.pets),
        petsToDelete: deletePetName,
        memo: customerInfo.memo,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CUSTOMER_DETAIL, id],
      })
      toast('고객 정보가 저장되었습니다.')
    },
    onError: () => {
      toast('고객 정보 저장에 실패했습니다.')
    },
  })

  const { mutate: createTicket } = useMutation({
    mutationFn: (ticketId: number) => {
      return createKindergardenCustomersTicket(
        profile?.petKindergarden?.id,
        parseInt(id),
        ticketId,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CUSTOMER_TICKET_ADD_LIST, id],
      })

      toast('이용권이 등록되었습니다.')

      setTicketModal(false)
      setSelectedTicketId(null)
    },

    onError: () => {
      toast('이용권 등록에 실패했습니다.')
    },
  })

  const handleCustomerInfo = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target

    setCustomerInfo({
      ...customerInfo,
      [name]: value,
    })
  }

  const handleHashtag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setTag(value.trim())
  }

  const onAddTag = (e: any) => {
    const { value } = e.target

    if (e.keyCode === 32 && value) {
      setCustomerInfo({
        ...customerInfo,
        pets: [
          ...customerInfo.pets,
          { id: customerInfo.pets.length, name: value },
        ],
      })

      setTag('')
    }
  }

  const onDeleteTag = (id: number) => {
    const newPetList = customerInfo.pets.filter((pet) => pet.id !== id)

    const deletePetList = customerInfo.pets
      .filter((pet) => pet.id === id)
      .map((pet) => pet.name)

    setCustomerInfo({
      ...customerInfo,
      pets: newPetList,
    })

    setDeletePetName([...deletePetName, ...deletePetList])
  }

  const handleFormValidation = (formValues: UserDetailInfo) => {
    let error: Partial<Error> = {}

    if (!validate.isPhone(formValues.phoneNumber)) {
      error.phoneNumber = '휴대폰 번호 형식이 올바르지 않습니다.'
    }

    if (!formValues.phoneNumber) {
      error.phoneNumber = '휴대폰 번호를 입력해주세요.'
    }

    if (!formValues.name) {
      error.name = '이름을 입력해주세요.'
    }

    if (!formValues.pets.length) {
      error.dogName = '강아지 이름을 입력해주세요.'
    }

    return error
  }

  const errorCheck = useMemo(
    () => handleFormValidation(customerInfo),
    [customerInfo],
  )

  const isChanged = useCallback(() => {
    const { name, phoneNumber, memo, pets } = customerInfo
    const petsName = pets.map((pet) => pet.name)
    const detailPetsName = detail?.customerPets.map((pet: Pet) => pet.name)

    const petCheck = petsName.some((pet: string) =>
      detailPetsName.includes(pet),
    )

    if (
      name === detail?.name &&
      phoneNumber === detail?.phoneNumber &&
      memo === detail?.memo &&
      petCheck &&
      petsName.length === detailPetsName.length
    ) {
      return false
    }

    return true
  }, [customerInfo, detail])

  const 필수값이입력되었는가 = useMemo(
    () => Object.keys(errorCheck).length === 0 && isChanged(),
    [errorCheck, isChanged],
  )

  const onSubmit = () => {
    updateDetail()
  }

  const goToList = () => {
    navigate('/main/customers')
  }

  const showModal = () => {
    setActiveModal(true)
  }

  const onActive = () => {
    updateActive(detail?.id)
    setActiveModal(false)
  }

  const filterPetAdded = useCallback(
    (customerInfo: Pet[]) => {
      const addPets = customerInfo.map((pet) => pet.name)

      const historyPets = detail?.customerPets.map((pet: Pet) => pet.name)

      const newList = addPets.filter((pet) => !historyPets?.includes(pet))

      return newList
    },
    [detail?.customerPets],
  )

  const showTicketModal = () => {
    setTicketModal(true)
  }

  const handleTicketSelect = (ticketId: number) => {
    setSelectedTicketId(ticketId)
  }

  const onTicketCreate = () => {
    createTicket(selectedTicketId as number)
  }

  return (
    <>
      <DetailTabContainer align="center">
        <DetailTabWrapper>
          <Tab tabs={USER_DETAIL_TAB_TITLE} currentTab={0} />
        </DetailTabWrapper>

        <Button color="line" size="tiny" onClick={goToList}>
          <Text typography="14_Md" color="gray400">
            목록
          </Text>
        </Button>
      </DetailTabContainer>
      <DetailContainer>
        <Flex gap={148}>
          <Flex direction="column" width={'30%'}>
            <Text typography="18_Sb" color="gray600">
              고객 정보
            </Text>

            <Spacing size={24} />

            <TextField
              label="보호자 성함"
              required
              name="name"
              value={customerInfo.name}
              onChange={handleCustomerInfo}
              hasError={Boolean(errorCheck.name)}
              errorMessage={errorCheck.name}
            />

            <Spacing size={20} />

            <TextField
              label="휴대폰 번호"
              name="phoneNumber"
              required
              value={customerInfo.phoneNumber}
              onChange={handleCustomerInfo}
              hasError={Boolean(errorCheck.phoneNumber)}
              errorMessage={errorCheck.phoneNumber}
            />

            <Spacing size={20} />

            <TextField
              label="강아지"
              name="pets"
              required
              onKeyUp={onAddTag}
              value={tag}
              onChange={handleHashtag}
              hasError={Boolean(errorCheck.dogName)}
              errorMessage={errorCheck.dogName}
            />

            <Text typography="14_Rg" color="gray500" css={{ marginTop: 8 }}>
              강아지 여러 마리일 경우 ‘스페이스바’ 로 구분
            </Text>

            <Flex css={{ flexWrap: 'wrap' }}>
              {customerInfo?.pets?.map((pet: Pet) => {
                return (
                  <TagText
                    key={pet.id}
                    typography="12_Md"
                    color="gray500"
                    margin="8px 0 0 0"
                    onClick={() => onDeleteTag(pet.id)}
                  >
                    {pet.name}
                    <CancelIcon />
                  </TagText>
                )
              })}
            </Flex>

            <Spacing size={20} />

            <TextArea
              label="메모"
              name="memo"
              placeholder="보호자 및 강아지의 견종, 성격, 알러지 등 정보를 메모할 수 있어요."
              value={customerInfo.memo}
              onChange={handleCustomerInfo}
            />

            <Text typography="14_Rg" color="gray500" margin="12px 0 0 0">
              {`고객 정보 등록일 ${generateDate(detail?.createdAt, true)}`}
            </Text>

            <Spacing size={64} />

            <Flex gap={20}>
              <Button color="line" size="mediumMiddle" onClick={showModal}>
                <Text typography="14_Md" color="gray400">
                  {detail?.isActive ? '고객 비활성화' : '고객 활성화'}
                </Text>
              </Button>
              <Button
                color="primary"
                size="mediumMiddle"
                onClick={onSubmit}
                disabled={!필수값이입력되었는가}
              >
                <Text typography="14_Md" color="white">
                  고객 정보 저장
                </Text>
              </Button>
            </Flex>

            <Text typography="12_Rg" color="gray500" margin="12px 0 0 0">
              장기 미이용 고객 등을 비활성화할 수 있습니다. 비활성 고객은 알림
              등이 <br /> 전송되지 않으며 추후 다시 활성화할 수 있습니다.
            </Text>
          </Flex>

          <Flex direction="column" flex={1}>
            <Flex justify="space-between">
              <Text typography="18_Sb" color="gray600" margin="0 0 32px 0">
                이용권 등록내역
              </Text>

              <Button
                size="tiny"
                style={{ marginBottom: '20px' }}
                onClick={showTicketModal}
              >
                <Text typography="14_Md" color="white">
                  이용권 등록
                </Text>
              </Button>
            </Flex>

            <div style={{ position: 'relative' }}>
              <Table head={USER_DETAIL_TICKET_ADD_TABLE_HEAD}>
                {ticketAddList?.results.length ? (
                  <>
                    {ticketAddList.results.map((content) => {
                      const { ticketType } = content
                      const icon =
                        ticketType === '시간'
                          ? yellow
                          : ticketType === '종일'
                            ? red
                            : blue
                      return (
                        <tr key={content.id}>
                          <td>
                            <CellFlex
                              style={{ display: 'inline-block' }}
                              ticketType={content.ticketType}
                            >
                              <img src={icon} alt="아이콘" />
                              <TableTicketText
                                typography="12_Md"
                                ticketType={content.ticketType}
                              >
                                {content.ticketType}
                              </TableTicketText>
                            </CellFlex>
                          </td>
                          <td>
                            {ticketType === '시간'
                              ? `${content.usageTime}시간`
                              : '-'}
                          </td>
                          <td>{content.usageCount}회</td>
                          <td>{content.usagePeriodInDaysCount}일</td>
                          <td>{addDelimiter(content.price)}원</td>
                          <td>{generateDate(content.createdAt)}</td>
                          <td>{generateDate(content.expiredAt)}</td>
                          <td>{content.status}</td>
                        </tr>
                      )
                    })}
                  </>
                ) : (
                  <NoList
                    title="등록내용이 없다멍..."
                    imgIcon={<NoListIcon />}
                    top={'100px'}
                    left={'43%'}
                  />
                )}
              </Table>
              {ticketAddList?.results.length ? (
                <Pagination
                  setOffset={setTicketAddOffset}
                  currentPage={currentTicketAddPage}
                  setCurrentPage={setCurrentTicketAddPage}
                  limit={ticketAddList.limit}
                  count={ticketAddList.count}
                  next={ticketAddList.next}
                  previous={ticketAddList.previous}
                />
              ) : null}
            </div>

            {ticketAddList && ticketAddList?.results.length > 0 ? (
              <Spacing size={64} />
            ) : (
              <Spacing size={165} />
            )}

            <Text typography="18_Sb" color="gray600" margin="0 0 32px 0">
              이용권 사용내역
            </Text>

            <div style={{ position: 'relative' }}>
              <>
                <Table head={USER_DETAIL_TICKET_USED_TABLE_HEAD}>
                  {ticketUsedList?.results.length ? (
                    <>
                      {ticketUsedList.results.map((content) => {
                        const {
                          ticketType,
                          isAttended,
                          reservationStatus,
                          reservedAt,
                        } = content

                        const icon =
                          ticketType === '시간'
                            ? yellow
                            : ticketType === '종일'
                              ? red
                              : blue

                        const isAttendedText =
                          isAttended === null
                            ? '-'
                            : isAttended
                              ? '등원'
                              : '결석'

                        const isReservation =
                          reservationStatus === '취소' ? '취소' : ''
                        return (
                          <tr key={content.id}>
                            <td>
                              <CellFlex
                                style={{ display: 'inline-block' }}
                                ticketType={content.ticketType}
                              >
                                <img src={icon} alt="아이콘" />
                                <TableTicketText
                                  typography="12_Md"
                                  ticketType={content.ticketType}
                                >
                                  {content.ticketType}
                                </TableTicketText>
                              </CellFlex>
                            </td>
                            <td>
                              {ticketType === '시간'
                                ? `${content.usageTime}시간`
                                : '-'}
                            </td>
                            <td>
                              {`${content.usageCount}${ticketType === '호텔' ? '박' : '회'}`}
                            </td>
                            <td>{content.usedCount}</td>
                            <td>{content.unusedCount}</td>
                            <td>{isAttendedText}</td>
                            <td>{generateDate(content.reservedAt)}</td>
                            <td>{generateDate(content.expiredAt)}</td>
                            <td>{isReservation}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <NoList
                      title="사용내용이 없다멍..."
                      imgIcon={<NoListIcon />}
                      top={'100px'}
                      left={'43%'}
                    />
                  )}
                </Table>

                {ticketUsedList?.results.length ? (
                  <Pagination
                    setOffset={setTicketUsedOffset}
                    currentPage={currentTicketUsedPage}
                    setCurrentPage={setCurrentTicketUsedPage}
                    limit={ticketUsedList.limit}
                    count={ticketUsedList.count}
                    next={ticketUsedList.next}
                    previous={ticketUsedList.previous}
                  />
                ) : null}
              </>
            </div>
          </Flex>
        </Flex>

        <Modal
          title={`${`이 고객을 ${detail?.isActive ? '비활성화' : '활성화'} 할까요?`}`}
          description={`${detail?.isActive ? '비활성화해도 이미 예약된 내역은 유지됩니다.' : ''} `}
          showModal={activeModal}
          setShowModal={setActiveModal}
          leftButtonLabel="취소"
          rightButtonLabel={`${detail?.isActive ? '비활성화' : '활성화'}`}
          rightButtonOnClick={onActive}
        />

        {ticketModal && (
          <Modal
            title="이용권 등록"
            setShowModal={setTicketModal}
            rightButtonLabel="등록하기"
            showModal={ticketModal}
            rightButtonOnClick={onTicketCreate}
            disabled={selectedTicketId === null}
            resetState={setSelectedTicketId}
            modalGap={ticketList?.length ? 40 : 12}
            body={
              <>
                {ticketList?.length ? (
                  <TicketBox>
                    {ticketList?.map((ticket: CustomerTicket) => {
                      const { ticketType, id } = ticket

                      return (
                        <TicketListRow
                          key={id}
                          ticket={ticket}
                          left={<TicketListRow.Left ticketType={ticketType} />}
                          contents={<TicketListRow.Contents ticket={ticket} />}
                          selectedTicketId={selectedTicketId}
                          onSelect={handleTicketSelect}
                        />
                      )
                    })}
                  </TicketBox>
                ) : (
                  <Flex justify="center">
                    <Text typography="14_Rg" color="gray500" textAlign="center">
                      생성한 이용권이 없습니다. <br />
                      이용권 관리에서 이용권을 먼저 생성해주세요.
                    </Text>
                  </Flex>
                )}
              </>
            }
          />
        )}
      </DetailContainer>
    </>
  )
}

const DetailContainer = styled.section`
  padding: 40px 80px;
`

const DetailTabContainer = styled(Flex)`
  border-bottom: 1px solid ${colors.gray200};
  padding-right: 80px;
`

const DetailTabWrapper = styled(Flex)`
  flex: 1;
  margin: 50px 0px 0px 80px;
`

const CellFlex = styled(Flex)<{ ticketType?: string }>`
  padding: 4px 8px;
  border-radius: 4px;

  ${({ ticketType }) =>
    ticketType === '시간' &&
    css`
      background-color: ${colors.yellow50};
      color: ${colors.yellow600};
    `}
  ${({ ticketType }) =>
    ticketType === '종일' &&
    css`
      background-color: ${colors.red50};
      color: ${colors.red600};
    `}
    ${({ ticketType }) =>
    ticketType === '호텔' &&
    css`
      background-color: ${colors.blue50};
      color: ${colors.blue600};
    `};

  img {
    margin-right: 4px;
    vertical-align: middle;
  }
`

const TableTicketText = styled(Text)<{ ticketType?: string }>`
  display: inline-block;
  ${({ ticketType }) =>
    ticketType === '시간' &&
    css`
      color: ${colors.yellow600};
    `}
  ${({ ticketType }) =>
    ticketType === '종일' &&
    css`
      color: ${colors.red600};
    `}
    ${({ ticketType }) =>
    ticketType === '호텔' &&
    css`
      color: ${colors.blue600};
    `};
`

const TagText = styled(Text)`
  width: max-content;
  font-size: 12px;
  background-color: ${colors.gray100};
  padding: 4px 4px;
  margin-right: 4px;
  border-radius: 4px;

  svg {
    margin-left: 4px;
    cursor: pointer;
  }
`

const TicketBox = styled.div`
  max-height: 440px;
  overflow-y: auto;
  margin-bottom: 20px;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export default CustomerDetail
