import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextareaHTMLAttributes } from 'react'

import { colors } from '@styles/colors'
import Text from '@common/Text'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
}

const TextArea = ({ label, onFocus, onBlur, ...rest }: TextAreaProps) => {
  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    onBlur?.(e)
  }
  return (
    <>
      {label && (
        <Text color="gray500" typography="14_Md" css={TextStyles}>
          {label}
        </Text>
      )}
      <TextAreaInput onBlur={handleBlur} {...rest} />
    </>
  )
}

const TextAreaInput = styled.textarea`
  height: 180px;
  padding: 10px;
  border: 1px solid ${colors.gray200};
  border-radius: 5px;
  resize: none;
  font-family: 'Pretendard';

  &:focus {
    outline: none;
    border-color: ${colors.gray400};
  }

  &::placeholder {
    font-size: 14px;
    color: ${colors.gray300};
    font-weight: 400;
    line-height: 18.2px;
    font-family: 'Pretendard';
  }
`
const TextStyles = css`
  margin-bottom: 8px;
`

export default TextArea
