import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'

import SignupPage from '@pages/auth/Signup'
import RenderPage from '@pages/auth/Render'
import RedirectPage from '@pages/auth/RedirectKaKao'
import Layout from '@layout/Layout'
import SchedulePage from '@pages/schedule/SchedulePage'
import TicketsPage from '@pages/ticket/TicketsPage'
import NotificationsPage from '@pages/NotificationsPage'
import TicketCreate from '@pages/ticket/TicketCreate'
import CustomersPage from '@pages/customer/CustomersPage'
import Navbar from '@layout/Navbar'
import CustomerDetail from '@components/customer/CustomerDetail'
import Footer from '@pages/Footer'
import QueryErrorBoundary from '@common/ErrorBoundary'
import EditInfoPage from '@pages/my/EditInfoPage'

// test test

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutWithNavBar />}>
          <Route path="/" element={<RenderPage />} />
        </Route>
        <Route
          element={
            <QueryErrorBoundary>
              <LayoutWithoutNavBar />
            </QueryErrorBoundary>
          }
        >
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/auth/kakao/callback" element={<RedirectPage />} />
          <Route path="/main" element={<Layout />}>
            <Route path="my" element={<EditInfoPage />} />
            <Route path="schedule" element={<SchedulePage />} />
            <Route path="customers" element={<CustomersPage />} />
            <Route path="customers/:id" element={<CustomerDetail />} />
            <Route path="ticket" element={<TicketsPage />} />
            <Route path="ticket/create" element={<TicketCreate />} />
            <Route path="notifications" element={<NotificationsPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const LayoutWithNavBar = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}

const LayoutWithoutNavBar = () => {
  return <Outlet />
}

export default App
