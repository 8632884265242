import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import CalendarTimeIcon from '@icon/CalendarTimeIcon'
import CalendarDayIcon from '@icon/CalendarDayIcon'
import CalendarHotelIcon from '@icon/CalendarHotelIcon'
import Text from '@common/Text'
import Flex from '@common/Flex'
import NoTicket from '@components/schedule/DirectlyRegister/NoTicket'

interface SelectTicketTypeProps {
  tickets: any
  selectedType: string
  selectedCustomer: any
  setSelectedType: (type: string) => void
}

const SelectTicketType = ({
  tickets,
  selectedType,
  selectedCustomer,
  setSelectedType,
}: SelectTicketTypeProps) => (
  <>
    {tickets?.time?.length > 0 ||
    tickets?.allDay?.length > 0 ||
    tickets?.hotel?.length > 0 ? (
      <>
        <Flex align="center">
          <Text
            color="gray500"
            typography="14_Md"
            style={{ margin: '0px 2px 8px 0px' }}
          >
            이용권 선택
          </Text>
          <Text color="red600">*</Text>
        </Flex>

        <Grid>
          {tickets?.time?.length > 0 && (
            <Wrap
              onClick={() => setSelectedType('time')}
              isActive={selectedType === 'time'}
              activeColor="yellow600"
              bgColor={colors.yellow50}
              justify="center"
              align="center"
              gap="4px"
            >
              <CalendarTimeIcon isActive={selectedType === 'time'} size={16} />
              <Text
                typography="14_Md"
                color={selectedType === 'time' ? 'yellow600' : 'gray500'}
              >
                시간 이용권
              </Text>
            </Wrap>
          )}

          {tickets?.allDay?.length > 0 && (
            <Wrap
              onClick={() => setSelectedType('allDay')}
              isActive={selectedType === 'allDay'}
              activeColor="red600"
              bgColor={colors.red50}
              justify="center"
              align="center"
              gap="4px"
            >
              <CalendarDayIcon isActive={selectedType === 'allDay'} size={16} />
              <Text
                typography="14_Md"
                color={selectedType === 'allDay' ? 'red600' : 'gray500'}
              >
                종일 이용권
              </Text>
            </Wrap>
          )}

          {tickets?.hotel?.length > 0 && (
            <Wrap
              onClick={() => {
                setSelectedType('hotel')
              }}
              isActive={selectedType === 'hotel'}
              activeColor="blue600"
              bgColor={colors.blue50}
              justify="center"
              align="center"
              gap="4px"
            >
              <CalendarHotelIcon
                isActive={selectedType === 'hotel'}
                size={16}
              />
              <Text
                typography="14_Md"
                color={selectedType === 'hotel' ? 'blue600' : 'gray500'}
              >
                호텔 이용권
              </Text>
            </Wrap>
          )}
        </Grid>
      </>
    ) : (
      <NoTicket selectedCustomer={selectedCustomer} />
    )}
  </>
)

export default SelectTicketType

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 8px;
  box-sizing: border-box;
`

const Wrap = styled(Flex)<{
  isActive: boolean
  activeColor: keyof typeof colors
  bgColor: string
}>`
  background-color: ${({ isActive, bgColor }) =>
    isActive ? bgColor : colors.gray100};
  border-radius: 8px;
  padding: 11px 9.17px;
  ${({ isActive, activeColor }) =>
    isActive && `border: 1px solid ${colors[activeColor]}`};
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
`
