export interface OptionType {
  label: string
  value: string
}

export const 예약여부: OptionType[] = [
  { value: '당일 예약 가능', label: '당일 예약 가능' },
  { value: '당일 예약 불가', label: '당일 예약 불가' },
]

export const 예약변경여부: OptionType[] = [
  { value: '당일 변경 가능', label: '당일 변경 가능' },
  { value: '당일 변경 불가', label: '당일 변경 불가' },
]
