import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Text from '@common/Text'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  unit?: string
  width?: string
}

const Input = ({
  onFocus,
  onBlur,
  onMouseDown,
  disabled,
  unit,
  width = '100%',
  ...rest
}: InputProps) => (
  <CustomLabel
    width={width}
    disabled={disabled}
    aria-invalid={rest['aria-invalid']}
  >
    <CustomInput
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseDown={onMouseDown}
      disabled={disabled}
      {...rest}
    />
    {unit && (
      <Text color="gray600" typography="14_Rg">
        {unit}
      </Text>
    )}
  </CustomLabel>
)

export default Input

const CustomLabel = styled.label<{ width: string; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;
  border: 1px solid ${colors.gray200};
  border-radius: 6px;
  width: ${({ width }) => width + 'px'};
  box-sizing: border-box;
  background: ${({ disabled }) => (disabled ? colors.gray50 : 'white')};

  &[aria-invalid='true'] {
    border-color: ${colors.red600};
  }
`

const CustomInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  height: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    color: ${colors.gray300};
    font-weight: 400;
    line-height: 18.2px;
    font-family: 'Pretendard';
  }

  &:disabled {
    color: ${colors.gray300};
  }
`
