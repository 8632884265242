import { useNavigate } from 'react-router-dom'

import Flex from '@common/Flex'
import CompleteList from '@components/signup/CompleteList'
import FormHead from '@components/signup/FormHead'

const Complete = () => {
  const navigate = useNavigate()

  const goToTicket = () => {
    navigate('/main/ticket')
  }

  return (
    <Flex direction="column">
      <FormHead complete />

      <CompleteList
        contents={
          <CompleteList.Contents
            title="STEP 1 회원가입"
            description="멍매니저의 파트너가 되었습니다!"
          />
        }
        isIcon
      />

      <CompleteList
        contents={
          <CompleteList.Contents
            title="STEP 2 이용권"
            description="시간권, 종일권, 호텔 이용권을 만들 수 있어요."
          />
        }
        buttonContents={
          <CompleteList.ButtonContents
            onClick={goToTicket}
            label="이용권 만들기"
          />
        }
        isButton={true}
      />

      <CompleteList
        contents={
          <CompleteList.Contents
            title="STEP 3 고객정보"
            description="고객인 보호자와 강아지 정보를 등록할 수 있어요."
          />
        }
      />

      <CompleteList
        contents={
          <CompleteList.Contents
            title="STEP 4 보호자 이용권 등록"
            description="각 보호자들에게 생성한 이용권을 등록할 수 있어요."
          />
        }
      />
    </Flex>
  )
}

export default Complete
