import styled from '@emotion/styled'

import { colors } from '@styles/colors'
import Flex from '@common/Flex'
import Text from '@common/Text'

interface TabProps {
  tabs: string[]
  onClick?: (id: number) => void
  currentTab?: number
}

const Tab = ({ tabs, onClick, currentTab }: TabProps) => {
  return (
    <>
      <TabContainer>
        {tabs.map((tab, index) => {
          return (
            <Flex key={index} direction="column">
              <TabButton
                onClick={() => {
                  if (onClick) {
                    onClick(index)
                  }
                }}
              >
                <TabTitle active={currentTab === index}>{tab}</TabTitle>
              </TabButton>
              <TabBorder active={currentTab === index} />
            </Flex>
          )
        })}
      </TabContainer>
    </>
  )
}

const TabContainer = styled(Flex)`
  gap: 16px;
`

const TabButton = styled.button`
  margin-bottom: 15.5px;
  cursor: pointer;
`

const TabTitle = styled(Text)<{ active: boolean }>`
  color: ${({ active }) => (active ? colors.gray600 : colors.gray400)};
`

const TabBorder = styled.div<{ active: boolean }>`
  height: 1px;
  width: 100%;
  border-bottom: ${({ active }) =>
    active ? `3px solid ${colors.gray600}` : 'none'};
`

export default Tab
