import { useQuery } from '@tanstack/react-query'

import { getKindergardenCustomers } from '@apis/customer/customer'
import { QUERY_KEY } from '@constants/queryKey'
import { CustomerLists } from '@/types/customer'

export const useCustomer = (
  offset: number,
  currentPage: number,
  id: number,
  keyword?: any,
  is_active?: boolean,
) => {
  const { data: customerList, refetch } = useQuery<CustomerLists>({
    queryKey: [QUERY_KEY.CUSTOMER_LIST, currentPage, keyword, is_active],
    queryFn: () => getKindergardenCustomers(id, offset, keyword, is_active),
    enabled: !!id,
    refetchOnMount: true,
  })

  return { customerList, refetch }
}
