import styled from '@emotion/styled'

import { useIntersection } from '@hooks/useIntersection'

interface ImageProps {
  src: string
  alt: string
  width?: number
  height?: number
}

const Image = ({ src, alt, width, height }: ImageProps) => {
  const { imgRef, imageSrc } = useIntersection<HTMLImageElement>(src)

  return (
    <ImgBox height={height}>
      <CustomImg src={imageSrc || ''} alt={alt} width={width} ref={imgRef} />
    </ImgBox>
  )
}

const CustomImg = styled.img<ImageProps>(({ width }) => ({
  width: width && `${width}px`,
}))

const ImgBox = styled.div<{ height?: number }>(({ height }) => ({
  height: height && `${height}px`,
}))

export default Image
