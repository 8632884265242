import styled from '@emotion/styled'
import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { TicketFormValues } from '@/types/ticket'
import { TICKET_TYPE_OPTIONS } from '@constants/ticket'
import { useCreateTicket } from '@hooks/ticket/useCreateTicket'
import { useToast } from '@contexts/ToastContext'
import Text from '@common/Text'
import Spacing from '@common/Spacing'
import Button from '@common/Button'
import Flex from '@common/Flex'
import Radio from '@common/Radio'
import TicketForm from '@components/ticket/TicketForm'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

const TicketCreate = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const petKindergardenId = useRecoilValue(petKindergardenIdState)
  const [selectedOption, setSelectedOption] = useState('time')
  const [formValues, setFormValues] = useState<TicketFormValues>({
    usageTime: '',
    usageCount: '',
    usagePeriodInDaysCount: '',
    price: '',
    ticketType: '시간',
  })

  const handleOptionChange = (value: string) => {
    setSelectedOption(value)

    setFormValues({
      usageTime: value === 'allDay' || value === 'hotel' ? '0' : '',
      usageCount: '',
      usagePeriodInDaysCount: '',
      price: '',
      ticketType:
        TICKET_TYPE_OPTIONS.find((option) => option.value === value)?.label ||
        '시간',
    })
  }

  const { mutate: createTicketMutation } = useCreateTicket({
    petKindergardenId: petKindergardenId as number,
    onSuccess: () => {
      navigate('/main/ticket')
    },
    onError: () => {
      toast('등록 실패했습니다.')
    },
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target

    if (name === 'usageTime' && parseInt(value, 10) > 23) {
      toast('24시간 이상은 종일권을 이용해 주세요')
      return
    }

    if (value === '' || /^[0-9]+$/.test(value)) {
      setFormValues((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  const handleFormValidation = (formValues: TicketFormValues) => {
    const { usageTime, usageCount, usagePeriodInDaysCount, price, ticketType } =
      formValues

    if (ticketType === '시간' && !usageTime) {
      return '사용 시간을 입력해주세요'
    }

    if (!usageCount) {
      return '횟수를 입력해주세요'
    }

    if (!usagePeriodInDaysCount) {
      return '사용 기간을 입력해주세요'
    }

    if (!price) {
      return '금액을 입력해주세요'
    }

    return ''
  }

  const errorCheck = useMemo(
    () => handleFormValidation(formValues),
    [formValues],
  )

  const handleSubmit = () => {
    createTicketMutation(formValues)
  }

  const navigateToList = () => {
    navigate('/main/ticket')
  }

  const isRequiredFieldsFilled = Object.keys(errorCheck).length === 0

  return (
    <Container>
      <Text typography="24_Sb">이용권 만들기</Text>

      <Spacing size={64} />

      <Radio
        options={TICKET_TYPE_OPTIONS}
        label="이용권 종류"
        required
        name="ticketType"
        onChange={handleOptionChange}
        selectedOption={selectedOption}
      />

      <Spacing size={24} />

      <TicketForm
        selectedOption={selectedOption}
        formValues={formValues}
        handleChange={handleChange}
      />

      <Spacing size={64} />

      <Flex justify="space-between">
        <Button color="normal" onClick={navigateToList}>
          취소
        </Button>

        <Button disabled={!isRequiredFieldsFilled} onClick={handleSubmit}>
          완료
        </Button>
      </Flex>
    </Container>
  )
}

export default TicketCreate

const Container = styled.section`
  max-width: 306px;
  margin: 0 auto;
  padding-top: 100px;
`
