import styled from '@emotion/styled'

import { useInternalRouter } from '@hooks/useInternalRouter'
import Button from '@common/Button'
import Text from '@common/Text'
import firstRenderImg from '@assets/images/render_1.png'
import secondRenderImg from '@assets/images/render_2.png'
import thirdRenderImg from '@assets/images/render_3.png'
import fourRenderImg from '@assets/images/render_4.png'
import Image from '@common/Image'

const RenderPage = () => {
  const router = useInternalRouter()
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_REST_API}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&response_type=code`

  const kakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL
    router.replace('/auth/kakao/callback')
  }

  return (
    <RenderContainer>
      <TestContainer />
      <RenderWrapper>
        <Image src={firstRenderImg} alt="render1" height={1500} />
        <Image src={secondRenderImg} alt="render2" height={2100} />
        <Image src={thirdRenderImg} alt="render3" height={2500} />
        <Image src={fourRenderImg} alt="render4" height={800} />
      </RenderWrapper>

      <ButtonBox>
        <Button size="mediumLarge" onClick={kakaoLogin}>
          <Text typography="16_Md" color="white">
            무료로 예약관리 시작하기
          </Text>
        </Button>
      </ButtonBox>
    </RenderContainer>
  )
}

export default RenderPage

const RenderContainer = styled.section`
  position: relative;
  width: 100%;
`

const TestContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1264px;
  background: linear-gradient(#fff6e0, white);
  z-index: -1;
`

const RenderWrapper = styled.div`
  width: 740px;
  margin: 0 auto;
`

const ButtonBox = styled.div`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);

  button {
    padding: 17.5px 24px;
  }
`
