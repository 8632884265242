const CalendarDayIcon = ({ isActive = false, size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.9961"
        cy="12.001"
        r="7"
        fill={isActive ? '#F49396' : '#C8CEDA'}
      />
      <path
        d="M11.9961 2.00098L11.9961 3.00149"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M11.9961 21L11.9961 22.0005"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M21.9961 12L20.9956 12"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M3 12L1.99949 12"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M4.92578 4.92871L5.63325 5.63618"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M18.3633 18.3652L19.0707 19.0727"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M19.0664 4.92871L18.3589 5.63618"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5.63672 18.3652L4.92925 19.0727"
        stroke={isActive ? '#F49396' : '#C8CEDA'}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CalendarDayIcon
