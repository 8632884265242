import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { colors } from '@styles/colors'
import { useTickets } from '@hooks/ticket/useTickets'
import { useDeleteTicket } from '@hooks/ticket/useDeleteTicket'
import Button from '@common/Button'
import Text from '@common/Text'
import Flex from '@common/Flex'
import Modal from '@common/Modal'
import EmptyTicket from '@components/ticket/EmptyTicket'
import Tickets from '@components/ticket/Tickets'
import Toast from '@common/Toast'
import { petKindergardenIdState } from '@atoms/petKindergardenId'

const TicketsPage = () => {
  const navigate = useNavigate()
  const petKindergardenId = useRecoilValue(petKindergardenIdState)
  const [activeModal, setActiveModal] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState<{
    id: number | null
    type: string | null
    usageTime: number | null
    usageCount: number | null
  } | null>(null)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const showModal = (
    ticketId: number,
    ticketType: string,
    ticketUsageTimeCount: number,
    ticketUsageCount: number,
  ) => {
    setSelectedTicket({
      id: ticketId,
      type: ticketType,
      usageTime: ticketUsageTimeCount,
      usageCount: ticketUsageCount,
    })
    setActiveModal(true)
  }

  const { tickets } = useTickets(petKindergardenId as number)

  const { mutate: deleteTicketMutation } = useDeleteTicket({
    petKindergardenId: petKindergardenId as number,
    onSuccess: () => {
      handleShowToast('이용권이 삭제되었습니다')
    },
    onError: () => {},
  })

  const isEmpty = (obj: any) => {
    return !obj || Object.keys(obj).length === 0
  }

  const isEmptyData = isEmpty(tickets?.data)

  const goToCreate = () => {
    navigate('/main/ticket/create')
  }

  const handleShowToast = (message: string) => {
    setToastMessage(message)
    setShowToast(true)
  }

  return (
    <Container>
      <Top justify="space-between" align="center">
        <Text typography="24_Sb">이용권 리스트</Text>
        <Button onClick={goToCreate}>이용권 만들기</Button>
      </Top>

      <Body
        isEmpty={isEmptyData}
        justify="center"
        align="center"
        direction="column"
      >
        {isEmptyData && <EmptyTicket />}

        {!isEmptyData && <Tickets tickets={tickets} showModal={showModal} />}
      </Body>

      <Modal
        title={
          <div>
            <strong>
              {selectedTicket?.type === '시간'
                ? `${selectedTicket?.usageTime}시간 • ${selectedTicket?.usageCount}회`
                : ` ${selectedTicket?.type} ${selectedTicket?.usageCount}회`}
            </strong>
            <br />
            이용권을 삭제하시겠습니까?
          </div>
        }
        leftButtonLabel="이전"
        rightButtonLabel="삭제하기"
        showModal={activeModal}
        setShowModal={setActiveModal}
        rightButtonOnClick={() => {
          if (!!selectedTicket?.id) {
            deleteTicketMutation(selectedTicket?.id)
            setActiveModal(false)
            setSelectedTicket(null)
          }
        }}
        leftButtonOnClick={() => {
          setSelectedTicket(null)
          setActiveModal(false)
        }}
      />

      <Toast
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
    </Container>
  )
}

export default TicketsPage

const Container = styled.section`
  width: 715px;
  margin: 0 auto;
  padding: 100px 0;
`

const Top = styled(Flex)`
  color: ${colors.gray600};
  font-weight: 600;
`

const Body = styled(Flex)<{ isEmpty: boolean }>`
  margin-top: 64px;
  padding: ${(props) => props.isEmpty && '80px 0'};
  border: ${(props) => props.isEmpty && `1px solid ${colors.gray200}`};
  border-radius: 10px;
  color: ${colors.gray400};
  display: ${(props) => (props.isEmpty ? 'flex' : 'grid')};
  grid-template-columns: ${(props) => !props.isEmpty && '1fr 1fr'};
  gap: ${(props) => !props.isEmpty && '20px'};

  > span:first-of-type {
    margin-top: 40px;
  }
  > span:last-of-type {
    margin-top: 12px;
  }
`
