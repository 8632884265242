import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import { Colors, colors } from '@styles/colors'
import { Typography, typographyMap } from '@styles/typography'

interface TextProps {
  typography?: Typography
  color?: Colors
  display?: CSSProperties['display']
  textAlign?: CSSProperties['textAlign']
  disabled?: boolean
  margin?: string
}

const Text = styled.span<TextProps>(
  ({ color = 'black', display, textAlign, disabled, margin }) => ({
    color: disabled ? colors.gray300 : colors[color],
    display,
    textAlign,
    margin,
  }),

  ({ typography = '16_Md' }) => typographyMap[typography],
)

export default Text
